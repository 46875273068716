@keyframes boxShadow--wripple {
  from { box-shadow: 0 0 .3rem 0 rgba(#000,.2); }
  to   { box-shadow: 0 0 .1rem .6rem rgba(#000,0); }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


@keyframes popIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    95% {
        transform: scale(1.05);
    }
    98% {
        transform: scale(.98);
    }
    100% {
        transform: scale(1);
    }
}


@keyframes slideIn--down {
    from {
        transform: translateY(-100%);
    }
    to {
      opacity: 1
    }
}

@keyframes slideIn--left {
    from {
        transform: translateX(100%);
    }
    to {
      opacity: 1
    }
}

@keyframes slideIn--right {
    from {
        transform: translateX(-100%);
    }
    to {
      opacity: 1
    }
}

@keyframes slideIn--up {
    from {
        transform: translateY(100%);
    }
    to {
      opacity: 1
    }
}

@keyframes growIn--height {
    from {
      height: 0;
    }
}

.animation--slideIn--up {
  @include animation(slideIn--up)
}

.animation--growIn--height {
  @include animation(growIn--height)
}

.animation--fadeIn,
.animations--fadeIn > * {
  @include animation(fadeIn)
}

.animation--content--fadeIn {
  & > * {
    @include transition;
    @include animation(fadeIn, slow)
  }
}

.animations--sequential {
  $eachItemsDelay: .03;
  $n: 0;
  @for $i from 1 through 25 {
      & > *:nth-child(#{$i}) {
        -webkit-animation-delay: #{$n}s !important;
        -moz-animation-delay: #{$n}s !important;
        -o-animation-delay: #{$n}s !important;
        animation-delay: #{$n}s !important;
      }
      $n: $n + $eachItemsDelay;
  }
}


.animation--wrapper {
  @include transition;
  height: auto;
}
