app-all-proposals app-proposal-preview > .column > .header { display: none }

app-create-proposal,
app-edit-proposal {

  &.create-proposal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background-color: var(--body--background-color);
  }

  .modal .header > a {
    @include flex;
    align-items: center;
    min-height: var(--minHitArea);
  }

  .proposal-options-wrapper {
    max-width: 100vw;
    overflow-x: auto;
    min-width: 100vw;
    padding: var(--space--smallest) var(--space) 3rem 0;
    display: flex;
    transform: translateX(calc(var(--padding--variable) * -1));

    @media (max-width: 800px) {
      background: linear-gradient(90deg, transparent 70%, rgba(0, 0, 0, 0.05));
      padding-top: var(--space);
    }
  }

  .proposal-options {
    display: grid;
    gap: var(--space--smaller);
    grid-template-columns: 20rem 20rem 20rem minmax(16.5rem, 20rem);
    grid-template-rows: auto 11rem 11rem;
    grid-template-areas:
      "c1 c2 c3 ."
      "td-0 td-1 td-2 r1"
      "td-3 td-4 td-5 r2";
    padding: 0 var(--padding--variable);
    flex-grow: 1;

  }

  .clump.is-placeholder {
    border-radius: var(--border-radius);
    background-color: rgba(0,0,0,.03);
    border: dashed 2px $border-color--black--light;
    color: var(--interaction);
    box-shadow: none;
  }

  .proposal-option {
    @include hoverEffect(color);
    border-radius: var(--border-radius);
    background-color: rgba(0,0,0,.03);
    border: dashed 2px $border-color--black--light;
    color: var(--interaction);
    font-size: var(--font-size--small);
    & > .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: none;
    }
    &.is-valid.is-touched {
      @include alertColors("success");
      border: none;
      padding: var(--space--small);
      cursor: unset
    }
    &.is-touched {
      padding: var(--space--small);
    }
    &:not(.is-valid) {
      @include flex;
      justify-content: center;
      align-items: center;
    }

    &.is-dirty:not(.is-valid) {
      // @include alertColors("warning")
    }

    &.is-touched:not(.is-valid) {
      @include alertColor("danger");
      border-color: var(--alert--danger);
      color: var(--alert--danger);
      box-shadow: 0 0 0.2rem .3rem var(--color--danger);
      // animation: wiggle forwards 1s ease-out;
      animation: shake .8s cubic-bezier(.36,.07,.19,.97) both;
    }

  }

  .proposal-enhancement,
  .proposal-discount {
    &.is-valid {
      &:hover, &:focus {
        background-color: #569735 !important;
      }
    }
  }

  .proposal-enhancement {
    .proposal-enhancement-image {
      background-repeat: no-repeat;
      background-size: cover;
      height: auto;
      width: 6rem;
      background-position: center;
    }
  }

  .list-item {
    .beans {
      margin-left: calc(var(--space--smaller) * -1);
    }
    .bean {
      &:not(.is-active) {
        background-color: transparent;
        border: $border-color--white--light;
        @include opacity(light)
      }
      &.is-active {
        background-color: rgba(255,255,255,.9);
        color: var(--alert--success)
      }
    }

    .list-item-compatabilities {
      display: grid;
      gap: var(--space--smaller);
      grid-template-columns: 4rem 4rem 4rem;
      grid-template-rows: 2rem 2rem;
      // grid-template-areas:
      //   "a b c ."
      //   "first-a first-b first-c first"
      //   "second-a second-b second-c second"
        // grid-template-areas:
        //   "td-0 td-1 td-2"
        //   "td-3 td-4 td-5"
    }
  }

  .modal {
    // height: clamp(75.8rem, 60vh, 100rem);
    height: clamp(75.8rem, 87vh, 90rem);
    width: clamp(30rem, 55rem, 70rem);

    @media (max-width: 550px) {
      width: 100vw;
    }

    @media (max-height: 700px) {
      height: 100vh;
    }

    .drawer {
      width: 100%;
      border-radius: 0;
    }

    table {

    --table-th--padding: var(--space--smaller);
    --table-td--padding: var(--space--smaller);

      td > input { padding: var(--space--smallest) var(--space--smallest) var(--space--smallest) var(--space--smaller) }
      tr > td:first-child.is-disabled ~ td {

        input {
          @include opacity(disabled);
          pointer-events: none;
        }
      }
    }

  }

  .optionTable {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
  }

}


mat-accordion.accordion--rebateAdjustment .mat-expansion-indicator,
mat-accordion.accordion--noArrow .mat-expansion-indicator {
 display: none;
}

//
.proposalOptions--hidePrice {
  .card.proposalOptions-brand-option .system-price,
  .comparisonGrid-cell--footer .comparisonGrid-cell-price,
  .comparisonGridFooter .comparisonGridFooter-paymentMethod,
  .comparisonGridFooter .comparisonGridFooter-financingOption,
  .comparisonGrid-cell--label.comparisonGrid-cell--footer > * {
    display: none
  }
}

// TABLE VIEW

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
app-table {

  ngx-datatable.ngx-datatable {
    height: 100%;
    @include flex(column);

    .visible {
      @include flex(column);
      flex-grow: 1;
      .datatable-body {
        flex-grow: 1;
        height: 10rem !important;
        overflow-y: scroll;
      }
    }

    .datatable-header {
      background-color: #FFF;
    }
  }

  .datatable-body-row {
    // border-bottom: $border--black--lightest;
  }

  .ngx-datatable .datatable-header .datatable-header-cell,
  .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell,
  .ngx-datatable .datatable-body .datatable-body-row .datatable-body-group-cell {
    padding: var(--space--smaller) var(--space--small);

    &:not(:last-child) {
      border-right: $border--black--lightest
    }

    &:first-child {
      padding-left: var(--space)
    }
    &:last-child {
      padding-right: var(--space)
    }
  }

    &:not(.cell-selection) .datatable-body-row:hover,
    &:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
      // @include hoverEffect('color')
    }

  datatable-header-cell.datatable-header-cell {
    display: flex !important;
    align-items: center !important;
    @include header(6)
  }

  .datatable-body-cell-label {
    line-height: 1.2
  }

  .ngx-datatable.material.single-selection .datatable-body-row.active,
  .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
  .ngx-datatable.material.multi-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
    background-color: var(--active);
    color: var(--active--contrast) !important;

    &:hover {
      background-color: var(--active);
    }

    .datatable-body-cell {
      color: var(--active--contrast);
    }
  }

  datatable-body-row.datatable-body-row.datatable-row-even {
    // background-color: hsl(215deg 26% 96%);
    background-color: $background-color--black--5;
    border-bottom: none;
  }

  .empty-row {
    font-size: var(--font-size--smallest);
    @include opacity(lightest);
    padding: var(--space);
    margin: var(--space) var(--space--small);
    font-style: italic;
  }

  .table-customerSearch {
    width: 11rem;
  }

  .table-consultantSearch {
    width: 13.2rem;
    display: inline-block;
  }

  .table-statusSearch {
    width: 11rem;
    display: inline-block;
  }

}

// VALIDATION

@keyframes shake {
  10%, 90% {
    transform: translate3d(-.1rem, .1rem, .1rem);
  }

  20%, 80% {
    transform: translate3d(.2rem, 0, -.1rem);
  }

  30%, 50%, 70% {
    transform: translate3d(-.3rem, -.1rem, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 1px, 1px);
  }
}

// Mobile proposal

// @media screen and (max-width: 600px) {
//   .presentationOverlay app-choose-proposal {
//     overflow: scroll;
//     .page--chooseProposal.page-content-main {
//       overflow: visible;
//       flex-grow: unset;
//       display: block;
//     }
//     .footer {
//       position: sticky;
//       bottom: 0;
//       background-color: #FFF;
//     }
//   }
// }

.jobs-loader .overlay.overlay--light {
  --overlay--background-color: rgb(255 255 255);
}

:root {
  --comparisonGrid-header--height : 13rem;
  --comparisonGrid-cell--height : 10rem;
  --comparisonGrid-footer--height : 9rem;

  --comparisonGrid-label--width : 18rem;
  --comparisonGrid-option--width : 20rem;

}

.comparisonGridOverlay > .modal {
  height: 95vh;
  width: 95vw;
}

.linkedProposalView .comparisonGridOverlay {
    .modal {
      height: calc(97vh - 24rem);
    }
  }

.comparisonGrid {
  display: flex;
  padding: 0;
  background-color: #EEEEF0;
  overflow-x: auto;
  overflow-y: auto;
  align-items: flex-start;
  height: 50rem;
  flex-grow: 1;
  position: relative;

  --icon--stroke-width: 3px;

  & > * {
    // label column and option columns
    height: fit-content;
    display: flex;
    flex-flow: column;
  }

  &-labels {
    width: var(--comparisonGrid-label--width);
    overflow-y: visible;
    flex-shrink: 0;
    position: sticky;
    left: 0;
    z-index: 2;
    display: none; // hidden by default on mobile

    .comparisonGrid-cell .comparisonGrid-cell-label {
      cursor: help;
      display: flex;
      background-position: left center;
      background-size: 24px 24px;
      font-size: 1.4rem;
      background-repeat: no-repeat;
      padding-left: 2.8rem;
      color: #2f80ed;
      min-height: 3rem;
      align-items: center;
      justify-content: center;
      text-align: left;
    }
  }


    &-option {
      width: var(--comparisonGrid-option--width);
      flex-shrink: 0;
      background-color: var(--body--background-color);
      z-index: 0;
      border-right: solid 1px rgba(255,255,255,.8);


      // Active Options on grid
      app-psis-comparison-grid-option.is-active &{
        box-shadow: 0 0 0.45rem -0.2rem var(--branding--secondary);
        z-index: 1;
        border-right-color: transparent;

        .comparisonGrid-cell:not(.comparisonGrid-cell--header):not(.comparisonGrid-cell--footer) {
          background-color: #FFF
        }

        .comparisonGrid-cell--footer {
          background-color: #FFF;
          border-top-color: var(--branding--secondary);
          background-color: var(--branding--secondary);
          color: var(--branding--secondaryContrast);
          .comparisonGrid-cell-select:after {
            content: 'ed';
          }
        }

        .comparisonGrid-cell--header {
          box-shadow: inset 0 0.4rem var(--branding--secondary), 0 -.6rem .8rem -.3rem var(--branding--secondary)
        }
        
      }

      // Only when inactive
      app-psis-comparison-grid-option:not(.is-active) & {
        cursor: pointer;
      }

      .comparisonGrid-cell.comparisonGrid-cell--footer {
        color: var(--interaction);
        cursor: pointer;
        .comparisonGrid-cell-price {
          font-weight: bold;
        }
        .comparisonGrid-cell-select {
          font-size: var(--font-size--smallest);
          margin-top: var(--space--smallest)
        }

      }




    }

    &-cell {
      display: flex;
      flex-flow: column;
      // padding: var(--space) var(--space--small) var(--space--small) var(--space--small);
      padding: 0 var(--space--small) ;
      height: var(--comparisonGrid-cell--height);
      flex-shrink: 0;
      border-bottom: solid 1px rgba(0,0,0,.05);
      background-color: var(--body--background-color);
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: default;
      transition: background-color ease-in-out .2s;

      &:not(.comparisonGrid-cell--footer),
      &:not(.comparisonGrid-cell--header) {
        .comparisonIcon,
        .comparisonGrid-cell-label {
          cursor: help;
        }
      }

      &.comparisonGrid-cell--header.comparisonGrid-cell--label {
        border-right: dashed 1px rgba(0,0,0,.05);
        border-bottom-color: rgba(0,0,0,.04);
      }

      &.is-empty {
        * { display: none; }
      }

      // .is-empty {
      //   &.comparisonIcon {
      //     // filter: saturate(0);
      //     display: none;
      //   }
      //   &.comparisonGrid-cell-label {
      //     display: none;
      //   }
      // }


      &--header {
        height: var(--comparisonGrid-header--height);
        min-height: var(--comparisonGrid-header--height);
        position: sticky;
        top: 0;
        background-color: #FFF;
        border-bottom: solid 1px rgba(109, 68, 68, 0.07);
        border-right: dashed 1px rgba(0,0,0,.05);
        justify-content: flex-end;
        padding: var(--space--small) var(--space--smallest);

        .comparisonGrid-optionHeader-image {
          margin-bottom: auto
        }

      }

      &--label {
        height: var(--comparisonGrid-cell--height);
        background-color: #FFF;
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: 0 var(--space--small);
        background-color: #FFF;
        overflow: hidden;
        border-right: solid 1px rgba(109, 68, 68, 0.1);
        align-items: start;
        icon { color: currentColor }
        
      }

      &--footer {
        position: sticky;
        bottom: 0;
        // background-color: #f5f5f5;
        border-top: solid 1px rgba(109, 68, 68, 0.1);
        justify-content: center;
        flex-shrink: 0;
        height: var(--comparisonGrid-footer--height);
      }

      .comparisonGrid-cell-label {
        font-size: 1rem;
        color: rgba(0,0,0,.7);
        text-align: center;
      }

    }

}

.comparisonGridFooter {
  gap: var(--space--smaller)
}

// Kill "help" cursor on all instances of savings. It isnt currently a "feature" in admin that has description, etc
.comparisonGrid-cell-label.icon--savings,
.comparisonGrid-cell.comparisonGrid-cell--savings .comparisonIcon ,
.comparisonGrid-cell.comparisonGrid-cell--savings comparisonGrid-cell-label {
  cursor: default !important;
}

// icons

  .comparisonIcon {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: var(--space--smallest);
  }

  .featuresAdmin-icon {
    width: 3.2rem;
    height: 3.2rem;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .icon--savings,
  .icon--cgidUtilityRebates,
  .icon--cgidFedTaxCred {
    // savings
    background-image: url('/assets/comparisonIcons/icon-dollar.svg');
  }
  .icon--0kCzyBO4NxeLu0PRTN5h {
    // seer
    background-image: url('/assets/comparisonIcons/icon-snowflake.svg');
  }
  .icon--6GBCUV8kgXTQFOVsJudK {
    //db
    background-image: url('/assets/comparisonIcons/icon-ear.svg');
  }
  .icon--YAx3JwhES7HXg7up2VCJ {
    // hspf
    background-image: url('/assets/comparisonIcons/icon-hspf.svg');
  }
  .icon--Q85agnLmFI8Z09zGQBbu {
    // afue
    background-image: url('/assets/comparisonIcons/icon-flame.svg');
  }
  .icon--iHcQUtbe34oNPb520xZA {
    // compressor speed
    background-image: url('/assets/comparisonIcons/icon-speedometer.svg');
  }
  .icon--7OU5JK7NJdEOMXhmzOOd {
    // humidity control
    background-image: url('/assets/comparisonIcons/icon-drop.svg');
  }
  .icon--bbxdo0LqUU6AmshlnN6h {
    // parts warranty
    background-image: url('/assets/comparisonIcons/icon-warranty.svg');
  }
  .icon--a9hsm5MeCQynODJy8nFT {
    // labor warranty
    background-image: url('/assets/comparisonIcons/icon-service.svg');
  }
  .icon--YuYk87KLSTFQFotUZA5t {
    // gas speed
    background-image: url('/assets/comparisonIcons/icon-speedometer.svg');
  }
  .icon--rsZviFaDQmrrhTVpY0t3 {
    // wifi
    background-image: url('/assets/comparisonIcons/icon-wifi.svg');
  }
  .icon--DzKxwg9jDFYJ6FgWmjvm {
    // communicating
    background-image: url('/assets/comparisonIcons/icon-connectivity.svg');
  }
  .icon--cedZyfEJhJphvoF7o9le {
    // blower motor
    background-image: url('/assets/comparisonIcons/icon-fan.svg');
  }
  .icon--N8px2JgoUTRH0hJMtVSW {
    // pro install
    background-image: url('/assets/comparisonIcons/icon-tools.svg');
  }
  .icon--m5EArmDWFvW7rOCOxYgZ {
    // m.b.g
    background-image: url('/assets/comparisonIcons/icon-ribbon.svg');
  }
  .icon--hBZekl7FLdLWQYfYE82b {
    // surge protect
    background-image: url('/assets/comparisonIcons/icon-surgeprotection.svg');
  }
  .icon--JikLisB7dr4aSb33hXoh {
    // routine maintenance
    background-image: url('/assets/comparisonIcons/icon-maintenance.svg');
  }
  .icon--NwtHpght1gq0nNVHZzKZ {
    // air filtration
    background-image: url('/assets/comparisonIcons/icon-filtration.svg');
  }
  .icon--CayJ0bEh4lc0CYOdweK3 {
    // air purification
    background-image: url('/assets/comparisonIcons/icon-purification.svg');
  }

// filtering

// responsive

  // @media (min-width: 1050px) {
  @media (min-width: 810px) {
    .comparisonGridOverlay > .modal {
      width: 97vw;
      height: 97vh;
      max-height: 125rem;
      max-width: 200rem;
    }
    
    :root {
      --comparisonGrid-cell--height: 6.8rem;
    }

    .comparisonGrid-labels {
      display: flex;
      flex-flow: column;
      order: -1000000;
    }

    .comparisonGrid-cell:not(.comparisonGrid-cell--label) .comparisonGrid-cell-label {
        display: none;
      }

    .comparisonGrid-cell .comparisonIcon {
        display: none;
    }

  }

  // @media (max-width: 1199px) {
  //   .comparisonGrid-cell.is-empty {
  //     height: 0;
  //     border: 0;
  //     padding: 0;
  //   }
  // }

  @media (max-width: 1100px) {

    .comparisonGridFooter-priceFields {
      display: none
    }

    .comparisonGrid-cell:not(.comparisonGrid-cell--label) {
      // shrink text slightly on mobile view
      font-size: 1.4rem;
    }

    // .comparisonGridFooter {
    //   &-priceFields * { display: none }
    // }
  }

  @media (min-width: 1101px) {

    .comparisonGridFooter-priceFields--compact {
      display: none
    }

    // .comparisonGridFooter {
    //   &-done > .group { display: none }
    // }
  }

  @media (max-width: 850px) {
    .comparisonGridOverlay {
      & > .modal {
        width: 97vw;
        height: 98vh; 
      }

      .modal > .header {
        padding: var(--space--smaller);
        .title, 
        .subtitle {
          display: none;
        }
      }
      
      .comparisonGrid-option {
        & > .comparisonGrid-cell--header {

        }
      }
    }
  }

  @media (max-width: 560px) {
    // collapse footer buttons to make room on narrow devices
    // this pixel size is related to the three buttons "compare options" "select & save" "back"
    .proposalView-footer {
      padding: var(--space--small);
      .button {
        padding: 0 var(--space--smallest);
        border: none;
      }
    }

    .comparisonGrid-option {
      border: none;
      margin: 0 var(--space--smallest);
      box-shadow: 0 0 .3rem .1rem rgba(0,0,0,.1);
    }

    .comparisonGridFooter {

      .comparisonGridFooter-paymentMethod {
        border: 0;
        padding: 0;
      }

      .comparisonGridFooter-paymentMethod .group .icon {
        display: none;
      }
      .comparisonGridFooter-financingOption {
        flex-basis: 10rem;
        flex-grow: 1;
      }

      .comparisonGridFooter-done button {
        padding: 0 1.3rem
      }

    }
  }

  // Tighten up vertical space on phones. the fixed header and footers kill visibility of actual grid features
  @media (max-height: 1000px) {
    .comparisonGrid {
      --comparisonGrid-header--height: 8rem;
      --comparisonGrid-footer--height: 8rem;

    }

    app-psis-comparison-grid-option.is-active .comparisonGrid-option .comparisonGrid-cell--header {
      box-shadow: inset 0 0.3rem var(--active);
    }

    .comparisonGrid-cell--header {
      padding: var(--space--smaller) 0;
    }

    .comparisonGrid-optionHeader-systemDetails {
      display: none
    }

  }