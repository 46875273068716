:root {
  --neumorphic--blur-offset: .5rem;
  --neumorphic--blur-width: 1.5rem;
  --neumorphic-box-shadow--dark: rgba(var(--box-shadow--rgb--dark), .2);
  --neumorphic-box-shadow--light: rgba(var(--box-shadow--rgb--light), .8);
}

.bump,
.dump,
.clump {
  border-radius: var(--border-radius--large);
  padding: var(--space--large);
}

.bump {
  box-shadow:
    var(--neumorphic--blur-offset) var(--neumorphic--blur-offset) var(--neumorphic--blur-width) var(--neumorphic-box-shadow--dark),
    .1rem 0 .1rem rgba(var(--box-shadow--rgb--dark), .05),
    calc(var(--neumorphic--blur-offset) * -1) calc(var(--neumorphic--blur-offset) * -1) var(--neumorphic--blur-width) var(--neumorphic-box-shadow--light),
    inset 1rem 1rem 2rem rgba(var(--box-shadow--rgb--dark), .02);
  background-color: $background-color--white--5;
}

.dump {
  --neumorphic--blur-offset:.3rem;
  background-color: rgba(0,0,0,.03);
  box-shadow:
    inset var(--neumorphic--blur-offset) var(--neumorphic--blur-offset) var(--neumorphic--blur-width) var(--neumorphic-box-shadow--dark),
    inset calc(var(--neumorphic--blur-offset) * -1) calc(var(--neumorphic--blur-offset) * -1) var(--neumorphic--blur-width) var(--neumorphic-box-shadow--light),
    inset -.1rem 0rem .1rem rgba(var(--box-shadow--rgb--light), .8),
    inset -0rem -.1rem .2rem rgba(var(--box-shadow--rgb--light), .4),
    inset .1rem .1rem .2rem rgba(var(--box-shadow--rgb--dark), .1);

}

.clump {
  @include box-shadow(0)
}
