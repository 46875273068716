.drawer {

  &--left,
  &--right,
  &--top,
  &--bottom {
    --drawer--border-radius: 2.5rem;
    --drawer--overlay-background-color: rgba(51, 65, 96, .2);
    --drawer--sideMargin: 0; // the space on the "dead" sides of the drawer // IE - if its drawer--left, this would be the gap on the top and bottom

    &:not(.is-open) {
      display: none;
    }

    @include flex(column);
    @include box-shadow(3);
    position: absolute;
    @include layer(3);
    @include transition;
    @include animation(fadeIn);
    overflow: hidden;
    background: var(--drawer--background, var(--organism--background--default));
    color: var(--drawer--color, var(--organism--color--default));
    box-shadow: $box-shadow--3, 0 0 0 10000rem var(--drawer--overlay-background-color);

    &:not([class*='margin-']) {
      margin: 0 !important; // !important to kill any margins- paddings- >
    }

    @include targetScreenSizeRange(mobile) {
      --drawer--border-radius: 0;
    }

  }

  &--left {
    left: 0;
    top: var(--drawer--sideMargin);
    bottom: var(--drawer--sideMargin);
    border-top-right-radius: var(--drawer--border-radius);
    border-bottom-right-radius: var(--drawer--border-radius);
    @include animation(slideIn--right)
  }
  &--right {
    right: 0;
    top: var(--drawer--sideMargin);
    bottom: var(--drawer--sideMargin);
    border-top-left-radius: var(--drawer--border-radius);
    border-bottom-left-radius: var(--drawer--border-radius);
    @include animation(slideIn--left)
  }
  &--top {
    top: 0;
    left: var(--drawer--sideMargin);
    right: var(--drawer--sideMargin);
    border-bottom-left-radius: var(--drawer--border-radius);
    border-bottom-right-radius: var(--drawer--border-radius);
    @include animation(slideIn--down)
  }
  &--bottom {
    bottom: 0;
    left: var(--drawer--sideMargin);
    right: var(--drawer--sideMargin);
    border-top-left-radius: var(--drawer--border-radius);
    border-top-right-radius: var(--drawer--border-radius);
    @include animation(slideIn--up)
  }
}


//------------------------------------------------------------------------------

.drawer > .header .titles:not(.t1):not(.t2):not(.t3):not(.t4):not(.t5):not(.t6) {
  @include titles(4);
}
