.gridRow {
  --gridRow--column--width: #{map-get($config--ui--sizes--generic, large)}; // Default
  --gridRow--column--sizing: auto-fill;
  --gap--column: var(--space);
  --gap--row: var(--space);

  &.gridRow--fill {
    // Makes columns grow to fill space instead of filling with potentially empty columns
    --gridRow--column--sizing: auto-fit
  }

  display: grid;
  grid-template-columns: repeat(#{var(--gridRow--column--sizing)}, minmax(var(--gridRow--column--width), 1fr));
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  grid-row-gap: var(--gap--row);
  grid-column-gap: var(--gap--column);

  // @media screen and (max-width: 1100px) {
  //   // move to a column on smaller screens to avoid grid width issues
  //   display: flex;
  //   flex-flow: column;
  // }
  //
  // & > * {
  //   align-self: stretch;
  // }

}

@each $name, $definition in $config--ui--sizes--generic {
    .gridRow--#{$name} {
      @extend .gridRow;
        --gridRow--column--width: #{$definition};

        // When we get to a certain screen size, set the column with to 100% so its never bigger than the screen
        @media (max-width: #{$definition}) {
          --gridRow--column--width: 100%;
        }
    }

}

// Create gap-- modifier to change gap
@each $name, $definition in $config--ui--spacing {
    .gap--#{$name} {
      --gap--column: #{$definition};
      --gap--row: #{$definition};
    }
    .column-gap--#{$name} {
      --gap--column: #{$definition};
    }
    .row-gap--#{$name} {
      --gap---gap--row-column: #{$definition};
    }
}

.gridRow:not(.gridRow--noAutoStack){
  @media screen and (max-width: 1100px) {
    // move to a column on smaller screens to avoid grid width issues
    --gridRow--column--width: 100%;
  }
}
