
////////////////////////////////////////////////////////////////////////////////
// Font Style

.u-italic {
  font-style: italic; }


////////////////////////////////////////////////////////////////////////////////
// Font Weight

.u-font-weight--normal {
  font-weight: $font-weight--normal !important; }

.u-font-weight--medium {
  font-weight: $font-weight--medium !important; }

.u-bold,
.u-font-weight--bold {
  font-weight: $font-weight--bold !important; }

.u-font-weight--black {
  font-weight: $font-weight--black !important; }

.u-font-weight--light {
  font-weight: $font-weight--light !important; }

.u-thin,
.u-font-weight--thin {
  font-weight: $font-weight--thin !important; }


////////////////////////////////////////////////////////////////////////////////
// Text Alignment

.u-text-align--left {
  text-align: left !important; }

.u-text-align--right {
  text-align: right !important;

    &.label {
    justify-content: flex-end; } // .label is flex, so we justify it
}

.u-text-align--center {
  text-align: center !important; }



////////////////////////////////////////////////////////////////////////////////
// Truncating & Wrapping

.u-truncate {
  // Text doesnt wrap, gets ellipsis
  @include wordWrap(none); }

.u-truncate--none {
  // Text wraps, no ellipsis
  @include wordWrap(normal); }

.u-wrap {
  @include wordWrap(normal); }

.u-breakText--all {
  // Contains user generated tasks and may consist of long strings of letters without space, therefore needed word-break: break-all;
  @include wordWrap(all); }


////////////////////////////////////////////////////////////////////////////////
// Case
.u-text-transform--lowercase {
  text-transform: lowercase; }

.u-text-transform--capitalize {
  text-transform: capitalize; }

.u-text-transform--uppercase {
  text-transform: uppercase; }

// Other

.u-line-height--small {
  line-height: 1.3em !important;
}

.u-line-height--smallest {
  line-height: 1;
}

.u-commas {
  & > *:not(:last-child) {
    &:after {
      content: ', ';
      padding-left: .1rem;
    }
  }
}
