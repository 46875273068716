.accordion.is-floating,
%banner,
.card,
%drillIn,
.dropdown,
.modal,
.navbar,
.panel,
.queue,
.sidebar,
.snippet,
.widget {
  display: flex;
  flex-flow: var(--organism--flex-direction);
  width: var(--organism--width);
  min-width: var(--organism--min-width);
  max-width: var(--organism--max-width);
  height: var(--organism--height);
  min-height: var(--organism--min-height);
  max-height: var(--organism--max-height);
  padding: var(--organism--padding);
  margin: var(--organism--margin);
  background: var(--organism--background, var(--organism--background--default));
  color: var(--organism--color, var(--organism--color--default));
  border: var(--organism--border);
  border-radius: var(--organism--border-radius);
  box-shadow: var(--organism--box-shadow);
  flex-shrink: 0;
  @include transition;

  & > .header {
    height: var(--organism-header--height, var(--header--height));
    min-height: var(--organism-header--min-height, var(--header--height)); }

  & > .footer {
    height: var(--organism-footer--height);
    min-height: var(--organism-footer--height); }

}
