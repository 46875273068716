.titles {
    @include truncate;
    @include flex(column);
    padding-right: var(--space); }

.title {
    font-weight: $font-weight--bold;
    @include truncate;
    &.u-wrap { line-height: 1.1; }
  }

.subtitle {
    @include opacity(light);
    @include truncate;
    line-height: 1.1;
    @include fontSize(small); }


//------------------------------------------------------------------------------

.titles {
  &.t1 { @include titles(1) }
  &.t2 { @include titles(2) }
  &.t3 { @include titles(3) }
  &.t4 { @include titles(4) }
  &.t5 { @include titles(5) }
  &.t6 { @include titles(6) }
}


//------------------------------------------------------------------------------

.header > .titles {
  flex-grow: 1;
}
