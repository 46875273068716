html {
  font-size: 62.5%;
}

body {
  font-size: var(--font-size--base);
  font-family: $font-family--default;
  color: var(--font-color--base);
  background-color: var(--body--background-color);
  overflow: hidden;
}

:focus {
    outline-color: var(--focus, var(--interaction--light));
}

::selection {
  background-color: var(--interaction);
  color: var(--interaction--contrast)
}
