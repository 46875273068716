.modal {
  --organism--background: var(--modal--background, var(--organism--background--default));
  --organism--color: var(--modal--color, var(--organism--color--default));
  --organism--box-shadow: #{$box-shadow--3};
  --organism--border-radius: var(--border-radius--large);
  max-height: -webkit-fill-available;
}

//------------------------------------------------------------------------------

.modal > .header .titles:not(.t1):not(.t2):not(.t3):not(.t4):not(.t5):not(.t6)  {
  @include titles(4);
}

//------------------------------------------------------------------------------

.modal .footer {
  padding-bottom: var(--space);
  &:not(.border) {
    border-top: none;
  }
}
