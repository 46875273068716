.u-border-radius--none {
  border-radius: 0; }

.u-border-radius {
  border-radius: var(--border-radius); }

.u-border-radius--large {
  border-radius: var(--border-radius--large); }

.u-border-radius--rounded,
.u-rounded {
  --input--border-radius: 1000rem;
  --border-radius: 1000rem;
}
