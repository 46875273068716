.card {
  --organism--background: var(--card--background, var(--organism--background--default));
  --organism--color: var(--card--color, var(--organism--color--default));
  --organism--box-shadow: #{$box-shadow--1};
  --organism--border-radius: var(--border-radius);
}

//------------------------------------------------------------------------------

.card > .header .titles:not(.t1):not(.t2):not(.t3):not(.t4):not(.t5):not(.t6) {
  @include titles(4);
}

//------------------------------------------------------------------------------

.cards {
  @include flex;
  padding: var(--space--large);
  flex-grow: 1;

  .card {
    --organism--width: var(--card--width);
    --organism--min-width: var(--card--width);
    --organism--margin: 0 var(--space--large) 0 0;
  }

}
