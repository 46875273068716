.sidebar {
  --organism--background: var(--sidebar--background, var(--organism--background--default));
  --organism--color: var(--sidebar--color, var(--organism--color--default));
  --organism--width: var(--sidebar--width);
  --organism--min-width: var(--sidebar--width);

  @include flex(column);
  overflow: hidden;
  flex-shrink: 0;
}

//------------------------------------------------------------------------------
// Modified version of the sidebar
.sidebar.is-expanded {
    --sidebar--width: 20rem;
}

.sidebar.is-floating {
    --organism--margin: var(--space);
    --organism--border-radius: var(--border-radius);
}

//------------------------------------------------------------------------------
// Main items inside sidebar

.sidebar-item {
  @include flex(column);
  @include hoverEffect(gray);
  @include truncate;
  @include fontSize(smallest);
  padding: var(--list-item--padding--tb) 0;
  min-height: var(--minHitArea);
  min-width: var(--minHitArea);
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:not(:first-child) {
    border-top: $border--black--light; }

  &.is-active {
    background-color: var(--active, var(--interaction));
    color: var(--active--contrast, var(--interaction--contrast)); }

}

// Internal sidebar-item items
.sidebar-item-label {
  text-transform: capitalize;

  .sidebar-item:not(:hover) & {
      @include opacity(lighter); }

  .sidebar-item-icon + & {
    margin-top: var(--space--smaller); }

}


//------------------------------------------------------------------------------
// Modified version of the sidebar

@mixin _sidebar--horizontal($choose--top_bottom: bottom) {

  @if ($choose--top_bottom == top) {
    order: -1;
  } @else if ($choose--top_bottom == bottom) {
    order: 2;
  }

  width: calc(100% - calc(var(--organism--margin) * 2) ); // not overriding --sidebar--width because I want to use it
  // on sidebar-item still
  @include flex(row);

  & > .dynamicBody {
    @include flex; }

  .sidebar-item {
    width: var(--sidebar--width); }

}

.sidebar.is-horizontal {
  @include _sidebar--horizontal;
}


@media (max-width: $screen--mobile) {

  .sidebar.is-responsive {

    .sidebar-item {
      border-top: none;
      flex-grow: 1; }

    .sidebar-item:not(:first-child) {
      border-left: 0.1rem solid rgba(var(--border--color--rgb--dark), 0.1); }

  }


 .sidebar.is-responsive.sidebar--bottom,
 .sidebar.is-responsive {
   @include _sidebar--horizontal(bottom); }

 .sidebar.is-responsive.sidebar--top {
   @include _sidebar--horizontal(top); }

 .body {
   flex-flow: column; }

}
