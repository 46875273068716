.u-font-size--smallest {
  font-size: var(--font-size--smallest); }

small,
.u-font-size--small {
  font-size: var(--font-size--small); }

.u-font-size--default {
  font-size: var(--font-size--base); }

.u-font-size--large {
  font-size: var(--font-size--large); }
