.chat {
    @include flex(column);
    padding: var(--space)
}

.chat-item {
    padding: 7px 10px; // var(--space);
    max-width: 75%;
    position: relative;
    border-radius: var(--border-radius--large);
    word-break: break-word!important;
    overflow-wrap: break-word!important;
    &.is-received {
        background-color: $background-color--black--4;
        width: fit-content;
        border-bottom-left-radius: 0px;
    }
    &.is-sent {
        background-color:  #6ebd90;
        color: white; //var(--interaction--darker)
        margin-left: auto;
        width: fit-content;
        border-bottom-right-radius: 0px;
    }
    
    &[data-timeStamp] {
        margin-bottom: var(--space--large);
        &:after {
            content: attr(data-timeStamp);
            @include position(absolute, unset, unset, calc(var(--space--large) * -0.65), 0);
            @include header(6);
            @include fontSize(smallest);
            @include opacity(lighter);
            width: max-content;
        }

        &.is-sent:after {
            @include position(absolute, unset, 0, calc(var(--space--large) * -0.65), unset);
            color: var(--font-color--base);
        }
        &.is-new{
            background-color: var(--interaction--lighter);
        }
    }
    &.is-lastMessageBlock {
        margin-bottom: var(--space--larger);
    }
}
.chat-item-senderInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    &.is-sent {
        justify-content: flex-end;
    }
    .avatar{
        width: auto;
        max-width: 20px;
        border-radius: 7px;
    }
    small{
        font-weight: bold;
    }
}
.dateDivider{
    display: flex;
    justify-content: center;
    .bean{
        font-size: var(--font-size--smallest);
    }
}