%banner {
  --organism--background: var(--banner--background, var(--organism--background--default));
  --organism--color: var(--banner--color, var(--organism--color--default));
  --organism--flex-direction: row;
  --organism--padding: var(--space--small) var(--space);
  align-items: center;
}

.banner {
  @extend %banner;
}

.banner--secondary {
  @extend %banner;
  --organism--background: transparent;
  border-bottom: $border--black--organism;
}
