////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// PRIMARY

// $font-family--default: 'Lato', Roboto, Arial, sans-serif;
// $font-family--display: 'Montserrat', Georgia, serif;
$font-family--default: 'Work Sans', Roboto, Arial, sans-serif;
$font-family--display: 'Work Sans', Georgia, serif;


//------------------------------------------------------------------------------
$screen--mobile--portrait: 414px;
$screen--mobile: 650px;
$screen--medium: 800px;
$screen--large: 1000px;
$screen--larger: 1200px;
$screen--largest: 1400px;


//------------------------------------------------------------------------------
$white: #FFFFFF;
$black: #000000;
$light: #f5f5f5;
$dark: #383838;


//------------------------------------------------------------------------------
// @NOTE if you change these, change them in customProperties.scss
// They need to be there for theming, they need to be here for calculations

$brand--1: map-get($config--ui--brandColors, brand--1);
$brand--2: map-get($config--ui--brandColors, brand--2);
$brand--3: map-get($config--ui--brandColors, brand--3);
$brand--4: map-get($config--ui--brandColors, brand--4);
$brand--5: map-get($config--ui--brandColors, brand--5);
$brand--6: map-get($config--ui--brandColors, brand--6);

$brand--1--contrast: rgba(255,255,255,.85);
$brand--2--contrast: #FFF;
$brand--3--contrast: #FFF;
$brand--4--contrast: rgba(0,0,0,.75);
$brand--5--contrast: #FFF;
$brand--6--contrast: rgba(255,255,255,.85);


//------------------------------------------------------------------------------
$orange: 	hsl(14, 100%, 53%);
$yellow: 	hsl(48, 100%, 67%);
$green: 	hsl(141, 53%, 53%);
$turquoise: 	hsl(171, 100%, 41%);
$cyan: 	hsl(204, 71%, 53%);
$blue: 	hsl(217, 71%, 53%);
$purple: 	hsl(271, 100%, 71%);
$red: 	hsl(360, 90%, 51%);

//------------------------------------------------------------------------------
$background-color--black--rgb: rgb(0, 7, 17);
$background-color--black--0: rgba($background-color--black--rgb, 1);
$background-color--black--1: rgba($background-color--black--rgb, .7);
$background-color--black--2: rgba($background-color--black--rgb, .3);
$background-color--black--3: rgba($background-color--black--rgb, .12);
$background-color--black--4: rgba($background-color--black--rgb, .06);
$background-color--black--5: rgba($background-color--black--rgb, .03);

//------------------------------------------------------------------------------
$background-color--white--rgb: rgb(255,255,255);
$background-color--white--0: rgba($background-color--white--rgb,1);
$background-color--white--1: rgba($background-color--white--rgb,.8);
$background-color--white--2: rgba($background-color--white--rgb,.55);
$background-color--white--3: rgba($background-color--white--rgb,.3);
$background-color--white--4: rgba($background-color--white--rgb,.15);
$background-color--white--5: rgba($background-color--white--rgb,.09);

//------------------------------------------------------------------------------
// $space--largest: map-get($config--ui--spacing, largest);
// $space--larger: map-get($config--ui--spacing, larger);
// $space--large: map-get($config--ui--spacing, large);
// $space: map-get($config--ui--spacing, default);
// $space--small: map-get($config--ui--spacing, small);
// $space--smaller: map-get($config--ui--spacing, smaller);
// $space--smallest: map-get($config--ui--spacing, smallest);


//------------------------------------------------------------------------------
$font-color--dark: #000000;
$font-color--default: #353535; // normal
$font-color--light: rgba(0,0,0,.65); // lighter secondary text
$font-color--lighter: rgba(0,0,0,.5); //
$font-color--lightest: rgba(0,0,0,.35); // Disabled, etc
$font-color--white: #FFFFFF;
$font-color--white--light: rgba(0,0,0,.7);

$font-weight--thin: 100;
$font-weight--light: 300;
$font-weight--normal: 400;
$font-weight--medium: 600;
$font-weight--bold: 700;
$font-weight--black: 900;

// $font-size: _customProperties.scss


//------------------------------------------------------------------------------
$opacity--light: .8;
$opacity--lighter: .65;
$opacity--lightest: .3;


//------------------------------------------------------------------------------
// BORDER COLORS ONLY
// DARK borders


$border-color--black--solid: rgba(var(--border--color--rgb--dark), 1);
$border-color--black--darker: rgba(var(--border--color--rgb--dark), .4);
$border-color--black--dark: rgba(var(--border--color--rgb--dark), .25);
$border-color--black--default: rgba(var(--border--color--rgb--dark), .15);
$border-color--black--light: rgba(var(--border--color--rgb--dark), .1);
$border-color--black--lighter: rgba(var(--border--color--rgb--dark), .07);
$border-color--black--lightest: rgba(var(--border--color--rgb--dark), .03);
// WHITE borders
$border-color--white--solid: rgba(var(--border--color--rgb--light), 1);
$border-color--white--darker: rgba(var(--border--color--rgb--light), .6);
$border-color--white--dark: rgba(var(--border--color--rgb--light), .38);
$border-color--white--default: rgba(var(--border--color--rgb--light), .2);
$border-color--white--light: rgba(var(--border--color--rgb--light), .12);
$border-color--white--lighter: rgba(var(--border--color--rgb--light), .07);
$border-color--white--lightest: rgba(var(--border--color--rgb--light), .04);

$border--none: none;

//------------------------------------------------------------------------------
// dont use rgba() sass function here, as is is overwritten in the mixin
$box-shadow--color: var(--box-shadow--rgb--dark);
  $box-shadow--0: 0 0 0 .1rem rgba($box-shadow--color, .15);
  $box-shadow--1: 0 0 .2rem 0 rgba($box-shadow--color, .15), 0 0 1rem 0 rgba($box-shadow--color, .15);
  $box-shadow--2: 0 0 .2rem 0 rgba($box-shadow--color, .1), 0 0 .7rem 0 rgba($box-shadow--color, .12), 0 0 2rem 0 rgba($box-shadow--color, .2);
  $box-shadow--3: 0 0 5rem 0 rgba($box-shadow--color, .5), 0 0 1rem .2rem rgba($box-shadow--color, .075), 0 0 .6rem -.2rem rgba($box-shadow--color, .05);

//------------------------------------------------------------------------------
// LAYERS (See layer mixin for more details and use)
  $layer--0: 0;
  $layer--1: 10;
  $layer--2: 20;
  $layer--3: 30;
  $layer--4: 50;
  $layer--5: 100; // Dropdowns
  $layer--high: 999; // Modals
  $layer--highest: 1000; // Timeouts, highest level info


//------------------------------------------------------------------------------
$animation--speedDefault: .3s;


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// DERIVED

$background-color--color--hover: var(--interaction--lightest);
$background-color--gray--hover: $background-color--black--5;
$background-color--white--hover: $background-color--white--4;

//------------------------------------------------------------------------------
$border--black--organism: .2rem solid rgba(34, 46, 72, 0.07); // divides organism like header/footer

$border--black--solid: .1rem solid $border-color--black--solid;
$border--black--darker: .1rem solid $border-color--black--darker;
$border--black--dark: .1rem solid $border-color--black--dark;
$border--black--default: .1rem solid $border-color--black--default;
$border--black--light: .1rem solid $border-color--black--light;
$border--black--lighter: .1rem solid $border-color--black--lighter;
$border--black--lightest: .1rem solid $border-color--black--lightest;

$border--white--solid: .1rem solid $border-color--white--solid;
$border--white--darker: .1rem solid $border-color--white--darker;
$border--white--dark: .1rem solid $border-color--white--dark;
$border--white--default: .1rem solid $border-color--white--default;
$border--white--light: .1rem solid $border-color--white--light;
$border--white--lighter: .1rem solid $border-color--white--lighter;
$border--white--lightest: .1rem solid $border-color--white--lightest;


//------------------------------------------------------------------------------
$checkbox--size: 1.8rem;
$checkbox--animationSpeed: .4s;


////////////////////////////////////////////////////////////////////////////////
// GENERIC
