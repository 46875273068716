
//------------------------------------------------------------------------------
$config--ui--sizes--generic: () !default;
@if variable-exists(config--ui--sizes--generic--custom) {
  $config--ui--sizes--generic: $config--ui--sizes--generic--custom;
} @else {
  $config--ui--sizes--generic: (
    smallest: 6rem, // sidebar type elements
    smaller: 10rem,
    small: 20rem,
    medium: 28rem, // queue, drawers, etc
    large: 38rem, // cards, modals?
    larger: 45rem,
    largest: 60rem,
  );
}


//------------------------------------------------------------------------------
$config--ui--spacing: () !default;
@if variable-exists(config--ui--spacing--custom) {
  $config--ui--spacing: $config--ui--spacing--custom;
} @else {
  $config--ui--spacing: (
    largest: 10rem,
    larger: 7.5rem,
    large: 5rem,
    default: 3rem,
    small: 1.6rem,
    smaller: .8rem,
    smallest: .4rem
  );
}


//------------------------------------------------------------------------------
$config--ui--brandColors: () !default;
@if variable-exists(config--ui--brandColors--custom) {
  $config--ui--brandColors: $config--ui--brandColors--custom;
} @else {
  $config--ui--brandColors: (
    // brand--1: #0a6ee2,
    brand--1: #18b1da,
    brand--1--contrast: #FFF,
    brand--2: #ee3f3f,
    brand--2--contrast: #FFF,
    brand--3: #00c832,
    brand--3--contrast: #FFF,
    brand--4: #ffbc2c,
    brand--4--contrast: rgba(0,0,0,.75),
    brand--5: #6a60f9,
    brand--5--contrast: #FFF,
    brand--6: #0a6ee2,
    brand--6--contrast: rgba(255,255,255,.85)
  );
}


//------------------------------------------------------------------------------
$config--ui--alertColors: () !default;
@if variable-exists(config--ui--alertColors--custom) {
  $config--ui--alertColors: $config--ui--alertColors--custom;
} @else {
  $config--ui--alertColors: (
    info: #c5d2d6, // #39bfe2,#c5d2d6
    info--contrast: rgba(0,0,0,.7),
    warning: #f5b429, // #ffbc2c,
    warning--contrast: rgba(0,0,0,.7),
    danger:#c70d0d, // #ee3f3f,
    danger--contrast: rgba(255,255,255,.8),
    success: #6abb42, //#00c832,
    success--contrast: #FFF
  );
}


//------------------------------------------------------------------------------
$config--ui--gradientColors: () !default;
@if variable-exists(config--ui--gradientColors--custom) {
  $config--ui--gradientColors: $config--ui--gradientColors--custom;
} @else {
  $config--ui--gradientColors: (
    directionDegree: 45deg,
    default--1: map-get($config--ui--brandColors, brand--1),
    default--2: #0292d4, // map-get($config--ui--brandColors, brand--6),
    warm--1: map-get($config--ui--brandColors, brand--4),
    warm--2: map-get($config--ui--brandColors, brand--2),
    cool--1: map-get($config--ui--brandColors, brand--1),
    cool--2: map-get($config--ui--brandColors, brand--5),
    mix--1: map-get($config--ui--brandColors, brand--4),
    mix--2: map-get($config--ui--brandColors, brand--1)
  );
}
