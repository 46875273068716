
// Accordion
mat-accordion {

  .mat-expansion-panel-header,
  .mat-expansion-panel-body {
    padding: 0;
  }
  .mat-expansion-indicator {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    transform-origin: center center;
  }
  .mat-expansion-panel-spacing {
    margin: 0; // Space when opened
  }
  .mat-expansion-panel-header.mat-expansion-toggle-indicator-before .mat-expansion-indicator {
    margin: 0 var(--space--smaller) 0 0;
  }
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}