.overlay {
  &:not(.is-open) {
    display: none;
  }
  @include flex(column);
  @include transition;
  @include animation(fadeIn);
  @include layer(highest);
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--overlay--background-color);
  margin: 0 !important; // in case it is used withing margins- etc
  padding: 0 !important;

  &--light {
    --overlay--background-color: #{$background-color--white--2};
    &.is-solid {
      --overlay--background-color: #{$background-color--white--0};
    }
  }

  &--transparent {
    --overlay--background-color: transparent; }

  &.is-hidden {
    visibility: hidden;
    --overlay--background-color: transparent;
    * {
      transform: scale(0);
      @include transition(fast);
    }
  }

}

.overlay--page {
  background-color: var(--body--background-color);
  @include flex(column);
  @include layer(highest);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column;
}
