

.alert {
  border-radius: var(--border-radius);
  padding: var(--padding--tb) var(--padding--lr);

  //-------------------------------
  // Internal elements

  .alert-title {
    margin: 0;
    padding: 0; }

  // set the header style on divs only,
  // this allows us to still use h1-5 and not override it
  div.alert-title {
    @include header(3); }

  .alert-body {
    @include opacity(light);
    margin: var(--space--smaller) 0 var(--space--small) 0; }

  //-------------------------------
  // Color variations
  &,
  &.is-info {
    @include alertColors(info); }

  &.is-success {
    @include alertColors(success); }

  &.is-warning {
    @include alertColors(warning); }

  &.is-danger {
    @include alertColors(danger); }


  & > .header {
    margin: calc(var(--space--large) * -1) calc(var(--space--large) * -1) 0 calc(var(--space--large) * -1);
  }


}
