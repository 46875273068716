
.avi {

  @include flex;
  align-items: center;
  justify-content: center;
  background-color: $background-color--black--4;
  @include box-shadow(0);
  font-size: var(--avi--font-size);
  position: relative;
  overflow: visible;
  width: var(--avi--size);
  height: var(--avi--size);
  border-radius: var(--avi--border-radius, calc( var(--avi--size) / 2 ));
  overflow: hidden;
  flex-shrink: 0;

  img {
    align-self: center;
    width: var(--avi--size);
    // height: var(--avi--size);
  }

  &.is-smallest {
    --avi--size: 2rem;
    --avi--font-size: 1.2rem;
  }

  &.is-small {
    --avi--size: 2.8rem;
    font-size: var(--font-size--small);
  }

  // Default
  --avi--size: 4.7rem;
  --avi--font-size: var(--font-size--large);

  &.is-large {
    --avi--size: 7.5rem;
    --avi--font-size: 3rem;
  }

  &.is-largest {
    --avi--size: 10rem;
    --avi--font-size: 4.5rem;
  }

  &.is-square {
    --avi--border-radius: 0rem;
  }

}
