.bean {
  @include flex;
  @include transition;
  align-items: center;
  padding: var(--space--small) var(--space);
  border-radius: var(--border-radius--large);
  background-color: $background-color--black--3;
  width: fit-content;

  // Size Variations
  &.is-small {
    padding: var(--space--smallest) var(--space--smaller);
    @include fontSize(small);
    line-height: 1.1; }

  // Color Variations
  &.is-primary {
    background-color: var(--interaction);
    color: var(--interaction--contrast); }

  &.is-active {
    background-color: var(--active);
    color: var(--active--contrast); }

  &.is-info {
    @include alertColors(info); }

  &.is-success {
    @include alertColors(success); }

  &.is-warning {
    @include alertColors(warning); }

  &.is-danger {
    @include alertColors(danger); }

}

//------------------------------------------------------------------------------
// "Selectable" or Interactive version
// Adds Hover Effect
.bean--interactive {
  cursor: pointer;
  @include hoverEffect(opacity);
  &:active {
    @include box-shadow(1);
    @include opacity(full); }
}
