.widget {
  --organism--background: var(--widget--background, var(--organism--background--default));
  --organism--color: var(--widget--color, var(--organism--color--default));
  --organism--box-shadow: #{$box-shadow--1};
  --organism--border-radius: var(--border-radius);
}

.widget > .header {
  border-top-left-radius: var(--organism--border-radius);
  border-top-right-radius: var(--organism--border-radius);

  &:only-child { border-bottom: none }
}

.widget > .header .titles:not(.t1):not(.t2):not(.t3):not(.t4):not(.t5):not(.t6):not(.is-responsive)  {
  @include titles(4);
}

//------------------------------------------------------------------------------
// PARENT CONTAINER

:root {
  --widgetSize: 38rem;
  --gutterSize: var(--space--large);
}

@media (max-width: $screen--mobile--portrait) {
  .widgets {
    --widgetSize: 20rem;
  }
}

//------------------------------------------------------------------------------

.widgets {
  display: grid;
  grid-gap: var(--gutterSize);
  padding: var(--gutterSize);
  grid-template-columns: repeat(auto-fit, minmax(var(--widgetSize), 1fr));
}

.widget-column {
  @include flex(column);
  .widget {
    align-self: initial;
    margin-bottom: var(--gutterSize)
  }
}

.widget {
  align-self: flex-start;
}

//

@media (max-width: $screen--mobile--portrait) {
  :root {
    --widgetSize: 1fr;
  }
}


//------------------------------------------------------------------------------
// UTILITIES AND MODIFIERS


//------------------------------------------------------------------------------
// This is set to the columnCount variable. If you change your base column structure, change this to match.
@for $i from 1 through 12 {

  .widget.span--#{$i} {
    grid-column: auto / span #{$i}; }

}


//------------------------------------------------------------------------------
// GUTTER MODIFIER CLASSES
// Sets gutter size modifiers for each of our preset spaces
@each  $size , $definition in $config--ui--spacing {

  .gutter--#{$size} {
    --gutterSize: #{$definition}; }

}

//------------------------------------------------------------------------------
// COLUMNS SIZE MODIFIER CLASSES + CHILD WIDTH
// Creates class that defines grids based on each of our generic sizes
@each  $size , $definition in $config--ui--sizes--generic {

  .widgets--#{$size} {
    --widgetSize: #{$definition}; }

  .widget.span--#{$size} {
    --widgetSize: #{$definition};
    width: $definition; }

}
