.queue {
  border-right: $border--black--default;
}

.queue + .page-content-main,
.queue {
  transition: all ease-out .2s;
}

// .queue .list {
//   $eachItemsDelay: .035;
//   $n: 0;
//   @for $i from 1 through 25 {
//       & > *:nth-child(#{$i}) {
//         -webkit-animation-delay: #{$n}s !important;
//         -moz-animation-delay: #{$n}s !important;
//         -o-animation-delay: #{$n}s !important;
//         animation-delay: #{$n}s !important;
//       }
//       $n: $n + $eachItemsDelay;
//   }
//
//   & > .list-item,
//   & > .list-option {
//     opacity: 0;
//     @include transition;
//     @include animation(fadeIn, slow)
//   }
//
// }

// Large
@media screen and (min-width: 800px) {
  .page:not(.queue--open) .queue {
    transform: translateX(-100%);
    width: 0;
    min-width: 0;
    opacity: 0;
    pointer-events: none;
  }

  // add responsive padding to the main area when the queue is open.
  // that way on larger screens there isnt a big gap on the left when the queue is closed
  .page.queue--open .queue + .padding--queue {
    padding-left: var(--padding--variable)
  }
}

// Mobile

@media screen and (max-width: 800px) {

  .queue {
    @include layer(1);
    position: absolute;
    bottom: 0;
    top: 6.6rem; // header height
    border-right: none;
    &.is-nested { top: 0; }
  }

  .page:not(.queue--open) .queue {
    transform: translateX(-100%);
    pointer-events: none; }

  .page.queue--open .queue {
    box-shadow: .1rem .4rem .4rem rgba(0,0,0,.6);
    & + * {
      opacity: .2;
      background-color: rgba(0,0,0,0);
    }
  }

}
