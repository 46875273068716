////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// 100% sizes
.u-width--full {
    width: 100%; }

.u-width--auto {
    width: auto; }

.u-width--screen {
    width: 100vw; }

.u-height--full {
    height: 100%; }

.u-height--auto {
    height: auto; }

.u-height--screen {
    height: 100vh; }

.u-size--full {
    width: 100%;
    height: 100%; }
.u-width--webkit-fill-available {
  width: -webkit-fill-available;
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// other sizes - double selector for speceficity
.u-width--content.u-width--content {
  width: max-content; }

.u-height--content.u-height--content {
  height: max-content; }

.u-size--content.u-size--content {
  width: max-content;
  height: max-content; }
.u-width--webkit-fill-available {
  width: -webkit-fill-available;
}
.u-width--fit-content {
  width: fit-content;
}
// .u-width--md {
//   width: map-get($config--ui--sizes--generic, medium); }
//
// .u-width--lg {
//   width: map-get($config--ui--sizes--generic, large); }

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

// this class doesnt let things get wider than the screen even when width classes are used
*[class*="u-width"]:not([class*="u-min-width"]) {
  max-width: 100vw !important
}

.u-max-width--screen {
    max-width: 100vw; }

.u-max-height--screen {
    max-height: 100vh; }

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
@mixin genericSize($choose--width-height-both, $generic-size) {
    @if ($choose--width-height-both == width) or ($choose--width-height-both == height) or ($choose--width-height-both == both)   {
          @if map-has-key($config--ui--sizes--generic,$generic-size) {
            @if ($choose--width-height-both == width) {
              width: map-get($config--ui--sizes--generic, $generic-size); }

            @if ($choose--width-height-both == height) {
              height: map-get($config--ui--sizes--generic, $generic-size); }

            @if ($choose--width-height-both == both) {
              height: map-get($config--ui--sizes--generic, $generic-size);
              width: map-get($config--ui--sizes--generic, $generic-size); }
          }

          @else {
            @warn "No value could be retrieved for `#{$generic-size}`. "
                + "Argument passed into genericSize() must be defined in `$config--ui--sizes--generic` map.";
          }

    }
    @else {
        @error "You tried to use #{$choose--width-height-both}... Argument passed into genericSize() must be 'width', 'height', or 'both' ";
    }

}


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// Loop to generate other sizes (not 100%)
// This loop generates a generic list of classes that can be used on elements.
// you can use only width, only height, or add both via the "size"

$config--ui--sizes--generic--types : (
  // value here becomes first css class word (ie .u-<this>--something)

  width: width, // ie - u-width--large
  height: height, // ie - u-height--smaller
  both: size // ie - u-size--larger
);

@each $axis, $axisClassName in $config--ui--sizes--generic--types {

  // create the general width and height class
  @each $sizeClassModifier, $definition in $config--ui--sizes--generic {

      .u-#{$axisClassName}--#{$sizeClassModifier}.u-#{$axisClassName}--#{$sizeClassModifier} {
          @include genericSize($axis, $sizeClassModifier);
      }

      @if $axis != both {

        // create the maximum width and height class
        .u-max-#{$axisClassName}--#{$sizeClassModifier}.u-max-#{$axisClassName}--#{$sizeClassModifier} {
          max-#{$axis}: $definition;
        }

        //  create the minimum width and height class
        .u-min-#{$axisClassName}--#{$sizeClassModifier}.u-min-#{$axisClassName}--#{$sizeClassModifier} {
          min-#{$axis}: $definition;
        }

      } @else if $axis == both {
        .u-min-#{$axisClassName}--#{$sizeClassModifier}.u-min-#{$axisClassName}--#{$sizeClassModifier} {
          min-width: $definition;
          min-height: $definition;
        }

        .u-max-#{$axisClassName}--#{$sizeClassModifier}.u-max-#{$axisClassName}--#{$sizeClassModifier} {
          max-width: $definition;
          max-height: $definition;
        }
      }
  }

}

.u-min-height--none {
  min-height: 0 !important;
}
