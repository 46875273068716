
.u-color--info {
  @include alertColor(info); }
.u-colors--info {
  @include alertColors(info); }

.u-color--success {
  @include alertColor(success); }
.u-colors--success {
  @include alertColors(success); }

.u-color--warning {
  @include alertColor(warning); }
.u-colors--warning {
  @include alertColors(warning); }

.u-color--danger {
  @include alertColor(danger); }
.u-colors--danger {
  @include alertColors(danger); }
