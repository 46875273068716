@use 'sass:math';

:root {
  --shape--size: 10rem;
  --shape--border-radius: 0;
}

%geometricShape {
  width: var(--shape--size);
  height: var(--shape--size);
  border-radius: var(--shape--border-radius);
  flex-shrink: 0;
  overflow: hidden;
}


@each $size, $px in $config--ui--sizes--generic {

  @if $size == medium {
    .square,
    .square--#{$size},
    .circle--#{$size},
    .circle {
      @extend %geometricShape;
      --shape--size: #{$px}; }

    .circle,
    .circle--#{$size} {
      --shape--border-radius: #{ceil(math.div($px, 2))}; }

  } @else {

    .square--#{$size},
    .circle--#{$size}{
      @extend %geometricShape;
       --shape--size: #{$px}; }

    .circle--#{$size} {
      --shape--border-radius: #{ceil(math.div($px, 2))}; }

  }

}
