

.button {
  @include flex;
  @include transition(fast, ease-out);
  @include truncate;
  cursor: pointer;
  min-width: var(--minHitArea);
  width: initial;
  min-height: var(--minHitArea);
  background-color: transparent;
  color: var(--interaction);
  font-size: var(--button--font-size, var(--font-size--base));
  padding: var(--button--padding-topBottom, 0) var(--button--padding-leftRight, var(--space));
  border: solid 1px var(--interaction--lighter);
  border-radius: var(--button--border-radius, var(--input--border-radius));
  align-items: center;
  line-height: 1;
  flex-shrink: 0;

  // Hover a normal button
  &:hover:not([disabled]),
  &:focus:not([disabled])  {
    background-color:  var(--interaction--lightest);
   }

  &:active { // on click hold
    @include box-shadow(2); }

  &[disabled]:not([disabled="false"]) {
    cursor: not-allowed;
    pointer-events: none;
    @include opacity(disabled)
  }

  // fix for u-fill height
  &.u-fill {
    min-height: var(--minHitArea); }

  //----------------------------------------------------------------------------
  // BUTTON VARIATIONS

  &.is-primary {
    background-color: var(--interaction);
    color:  var(--interaction--contrast);
    border-color:  var(--interaction);

    // Hover a primary button
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--interaction--dark);
      color: var(--interaction--contrast);
    }
  }

  &.is-light {
    background-color: transparent;
    color:  #FFF;
    border-color: #FFF;

    // Hover a primary button
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: #FFF;
      color: var(--interaction--darker);
    }
  }

  &.is-light.is-primary {
    background-color: #FFF;
    color: var(--interaction);
    border-color: #FFF;

    // Hover a primary button
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: #FFF;
      color: var(--interaction--darker);
    }
  }

  &.is-borderless {
    border: none;
  }

  &.is-unstyled {
    border: none;
  }

  &.is-contrast {
    color: var(--interaction--contrast);
    border-color: var(--interaction--contrast);
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--interaction--contrast);
      color: var(--interaction);
    }
  }
  &.is-secondary {
    background-color:#6c757d;
    color: var(--interaction--contrast);
    border-color: #6c757d;
  }
  //----------------------------------------------------------------------------
  &.is-small {
    --minHitArea: var(--minHitArea--small);
  }

  //----------------------------------------------------------------------------
  &.is-large {
    --minHitArea: 5.5rem;
  }
  &.is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    @include opacity(disabled)
  }

  //----------------------------------------------------------------------------
  // &.is-toggle {
  //   position: relative;
  //   overflow: visible;
  //   @include transition(fast);
  //   &::after {
  //     content: '';
  //     border-radius: var(--button--border-radius, var(--input--border-radius));
  //     position: absolute;
  //     top: 0;
  //     bottom: 0;
  //     left: 0;
  //     right: 0;
  //     box-shadow: none;
  //     @include transition(fast);
  //   }
  //   &:active {
  //     &::after{
  //       @include browserPrefix((
  //         box-shadow: 0rem 0.1rem 0.8rem 0rem inset $background-color--black--1
  //       ));
  //     }
  //   }
  //   &.toggled {
  //     &::after {
  //       @include browserPrefix((
  //         box-shadow: 0rem 0.1rem 0.6rem 0rem inset $background-color--black--2
  //       ));
  //     }
  //     &:not(:hover):not([disabled]),
  //     &:not(:focus):not([disabled]) {
  //       &:not(.is-contrast):not(.is-primary) {
  //         background-color: $background-color--black--5;
  //       }
  //     }
  //   }
  //   &:focus {
  //     box-shadow: none;
  //   }
  //   &:not(.toggled):not([disabled]):not(:hover):focus {
  //     &:not(.is-contrast):not(.is-primary) {
  //       background-color: transparent;
  //     }
  //   }
  //   // versions of toggle
  //   &.is-primary {
  //     &::after {
  //       top: -.1rem;
  //       bottom: -.1rem;
  //       left: -.1rem;
  //       right: -.1rem;
  //     }
  //     &:not(.toggled):not([disabled]):not(:hover):focus {
  //       background-color: var(--interaction);
  //     }
  //     &.toggled {
  //       &:not([disabled]) {
  //         background-color: var(--interaction--dark);
  //         &::after {
  //           @include browserPrefix((
  //           box-shadow: 0rem 0.1rem 0.8rem -0.1rem inset $background-color--black--1
  //           ));
  //         }
  //       }
  //     }
  //   }
  //   &.is-contrast {
  //     &::after {
  //       top: -.1rem;
  //       bottom: -.1rem;
  //       left: -.1rem;
  //       right: -.1rem;
  //     }
  //     &.toggled {
  //       &:not([disabled]) {
  //         &:hover::after {
  //           @include browserPrefix((
  //             box-shadow: 0rem 0.1rem 0.6rem 0rem inset $background-color--black--1
  //           ));
  //         }
  //       }
  //     }
  //   }
  // }

  //------------------------------------------------------------------------------

  // &.is-pullTab {
  //   position: absolute;
  //   background-color: $background-color--white--0;
  //   border: none;
  //   border-top-left-radius: 0;
  //   border-bottom-left-radius: 0;
  //   left: 100%;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   padding: 0;
  //   justify-content: center;
  //   @include layer(1);
  //   box-shadow: .15rem 0 .2rem 0 rgba(0, 0, 0, .3), .3rem .1rem .2rem .1rem rgba(0, 0, 0, 0.1);
  //   transform-origin: left;
  //   --icon-path--primary: currentColor;
  //   --icon-path--secondary: currentColor;
  //   &:hover:not([disabled]),
  //   &:focus:not([disabled]) {
  //     background-color: transparent;
  //   }
  //   &:hover {
  //     transform: translateY(-50%) scale(1.03);
  //     color: var(--interaction--darker);
  //     .icon {
  //       transform: translateX(.3rem); }
  //   }
  //   .icon,
  //   .coach-icon > .icon {
  //     @include transition(fast);
  //   }
  //   &.opened:not(.disable-rotate) .icon {
  //     transform: rotate(180deg); }
  //   &.opened:hover {
  //     transform: translateY(-50%) scale(.98);
  //     .icon {
  //       transform: translateX(-.4rem); }
  //     &:not(.disable-rotate) .icon {
  //       transform: translateX(-.4rem) rotate(180deg); }
  //   }
  // }

}


//------------------------------------------------------------------------------
// Default margins on icons
.button {
  // the not only child stops from adding padding to both sides if you
  // have a button > icon , text and know wrapper element around the text.
  // when that is the case, the icon is both the first and last child since
  // the text is not technically a child when it doesnt have a container
  & > .icon:first-child:not(:only-child):not([class*='margin-right--']) {
    margin-right: var(--space--smaller); }
  & > .icon:last-child:not(:only-child):not([class*='margin-left--'])  {
    margin-left: var(--space--smaller); }
}

//------------------------------------------------------------------------------
// text align doesnt work since it is flex, so we assume they want the flex center
// and set that instead
button.u-text-align--center {
  justify-content: center;
}

/*

// why variables arent good here

Notice that with buttons, its best to respond to a variable and tweak it with darken, etc...
We can just as easily set up something like

&:hover,
&:focus {
  background-color: darken($background--color, 20)
}

as opposed to

&:hover,
&:focus {
   --background-color: darken( var(--background-color) , 20 );
}



// what didnt work ---

button,
input[type="button"] {

  --background-color: $blue;
  --color: #FFF;

  background-color: var(--background-color);
  color: var(--color);

  &:hover,
  &:focus {
     --background-color: darken( var(--background-color) , 20 );
  }

  &:active {
    --background-color: black
  }
}

*/
