.u-background-color--white {
  background-color: #FFFFFF; }

.u-background-color--interaction {
  background-color: var(--interaction); }

.u-background-color--interaction--lightest {
  background-color: var(--interaction--lightest); }

.u-colors--interaction {
  color: var(--interaction--contrast);
  background-color: var(--interaction); }

.u-background-color--none {
  background-color: transparent; }

//------------------------------------------------------------------------------

.u-background-color--black {

  &--0,
  &--1,
  &--2,
  &--3,
  &--4,
  &--5 {
    background-color: hsla( var(--config--background-color--dark--h),var(--config--background-color--dark--s), var(--config--background-color--dark--l) ,var(--config--background-color--dark--a) )
  }
  &--1 {
    --config--background-color--dark--a: .7
  }
  &--2 {
    --config--background-color--dark--a: .3
  }
  &--3 {
    --config--background-color--dark--a: .12
  }
  &--4 {
    --config--background-color--dark--a: .06
  }
  &--5 {
    --config--background-color--dark--a: .03
  }

}


//------------------------------------------------------------------------------

.u-background-color--white {

  &--0 {
    background-color: $background-color--white--0; }
  &--1 {
    background-color: $background-color--white--1; }
  &--2 {
    background-color: $background-color--white--2; }
  &--3 {
    background-color: $background-color--white--3; }
  &--4 {
    background-color: $background-color--white--4; }
  &--5 {
    background-color: $background-color--white--5; }

}

//------------------------------------------------------------------------------

.u-background-color--brand--1 {
  background-color: var(--brand--1); }

.u-background-color--brand--2 {
  background-color: var(--brand--2); }

.u-background-color--brand--3 {
  background-color: var(--brand--3); }

.u-background-color--brand--4 {
  background-color: var(--brand--4); }

.u-background-color--brand--5 {
  background-color: var(--brand--5); }

.u-background-color--brand--6 {
  background-color: var(--brand--6); }


//------------------------------------------------------------------------------
// Gradients

%gradient {
  --gradient--directionDegree: 45deg;
  --gradient--background-color--1: var(--gradient--default--1);
  --gradient--background-color--2: var(--gradient--default--2);
  background: linear-gradient( var(--gradient--directionDegree), var(--gradient--background-color--1) 0%, var(--gradient--background-color--2) 100%);
}

.u-gradient--cool {
  @include brandGradient(cool); }

.u-gradient--warm {
  @include brandGradient(warm); }

.u-gradient--mix {
  @include brandGradient(mix); }

.u-gradient {
  @include brandGradient(default); }

.u-gradient--cool--left {
  @include brandGradient(cool, rtl); }

.u-gradient--warm--left {
  @include brandGradient(warm, rtl); }

.u-gradient--mix--left {
  @include brandGradient(mix, rtl); }

.u-gradient--default--left {
  @include brandGradient(default, rtl); }

.u-gradient--cool--up {
  @include brandGradient(cool, btt); }

.u-gradient--warm--up {
  @include brandGradient(warm, btt); }

.u-gradient--mix--up {
  @include brandGradient(mix, btt); }

.u-gradient--default--up {
  @include brandGradient(default, btt); }

.u-gradient--cool--down {
  @include brandGradient(cool, ttb); }

.u-gradient--warm--down {
  @include brandGradient(warm, ttb); }

.u-gradient--mix--down {
  @include brandGradient(mix, ttb); }

.u-gradient--default--down {
  @include brandGradient(default, ttb); }
