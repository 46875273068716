// Creates an attribute that can be applied to any element to make it sticky...
// NOTE: top position may not always be 0, and sticky sets that by default. Also -
// background color is needed on most items when they stick so that you dont see multiple stuck items
*[sticky] {
  @include position(sticky); }

// stickyBlock is an element meant to stick on scroll that contains other content inside, headers, icons, etc
// What makes it different is that it should inherit background color from the element it is used in
.u-position--sticky {
  @include position(sticky);
  background-color: inherit; }
