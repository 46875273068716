:root {
  --icon--size: 2.4rem;
  --icon--stroke-width: 2px;
}

.icon {
  @include flex;
  align-items: center;
  justify-content: center;
  width: var(--icon--size);
  height: var(--icon--size);
  @include transition;
  flex-shrink: 0;
}

.button.is-icon,
.button.icon {
  @include flex;
  align-items: center;
  justify-content: center;
  @include minHit;
  padding: 0;
  --icon-path--primary: currentColor;
  --icon-path--secondary: currentColor;

  &[data-label] {
    position: relative;
    flex-flow: column;
    justify-content: flex-end;
    padding-top: .1rem;
      &:after {
        content: attr(data-label);
        // @include position(absolute, unset, 0, .1rem, 0);
        // @include header(6);
        // @include fontSize(smallest);
        font-size: 1rem;
        @include opacity(lighter);
        text-align: center;
        @include truncate();
        text-transform: capitalize;
        padding: .2rem var(--space--smallest) .2rem var(--space--smallest);
        color: var(--font-color--base);
      }

      &.is-primary:after {
        color: var(--interaction--contrast);
      }

      &.is-sent:after {
          @include position(absolute, unset, 0, calc(var(--space--large) * -0.65), unset);
          color: var(--font-color--base);
      }
      &.is-new{
          background-color: var(--interaction--lighter);
      }
  }

}

.icon--selectable,
.icon.is-selectable {
  // NOTE - you should use icons in buttons for most situtations...
  // this can be used as a backup to make them appear clickable
  @include hoverEffect(opacity);
}

//------------------------------------------------------------------------------
// COLOR Modifiers

.icon--default {
  // this is set in case you need to override an icon inside of a modifier parent
  --icon-path--primary: #{$font-color--default};
  --icon-path--secondary: #{$font-color--lighter}; }

.icon--primary {
  --icon-path--primary: var(--interaction);
  --icon-path--secondary: var(--interaction--light); }

.icon--white {
  --icon-path--primary: #FFFFFF;
  --icon-path--secondary: rgba(255,255,255,.6); }

.icon--currentColor {
  --icon-path--primary: currentColor;
  --icon-path--secondary: currentColor; }

.icon--success,
.icon.u-color--success {
  --icon-path--primary: var(--alert--success);
  --icon-path--secondary: var(--alert--success); }


.icon--danger,
.icon.u-color--danger {
  --icon-path--primary: var(--alert--danger);
  --icon-path--secondary: var(--alert--danger); }

//------------------------------------------------------------------------------
// Size modifiers

.is-small {
  --icon--size: 1.6rem; }

.is-large {
  --icon--size: 3.2rem; }


//------------------------------------------------------------------------------
// THICC-NESS modifiers

.is-thin {
  --icon--stroke-width: 1px; }

.is-bolder {
  --icon--stroke-width: 4px; }

//------------------------------------------------------------------------------
// Rotate dem bois
.icon--rotate--x {
  &.is-rotated {
    transform: rotateX(180deg); }
}
.icon--rotate--y {
  &.is-rotated {
    transform: rotateY(180deg); }
}
