.templateOption {
  border: solid 2px rgba(0,0,0,.07);

  // Hide the proposal title, etc since its already shown in the non hidden
  // mat-expansion-panel-header
  app-template-preview {
    background-color: transparent;
    box-shadow: none;
    .column:first-child {
      > .header {
        display: none;
      }
      > .dynamicBody.padding {
        margin-top: var(--space--small);
        padding: var(--space--smallest);
      }
    }
  }

}

.templateOption.is-selected {
  background-color: #FFF;
  border-color: var(--active);
  box-shadow: 0 0 .4rem .1rem var(--active);
}


.proposalOption {

  // Hide the proposal title, etc since its already shown in the non hidden
  // mat-expansion-panel-header
  app-proposal-preview {
    background-color: transparent;
    box-shadow: none;
    .column:first-child > .header {
      display: none;
    }
  }

}

app-proposal-preview.is-selected {
  border: solid 1px var(--active);
  box-shadow: 0 0 .2rem var(--active)
}

.proposalOption.is-selected {

  app-proposal-condensed-expandable {
    box-shadow: 0 0 .4rem -.1rem var(--active), 0 0 .2rem .1rem var(--active);
  }

}


app-proposal-condensed-expandable app-proposal-preview.card {
  background-color: transparent;
  box-shadow: none;
  .column:first-child > .header {
    display: none;
  }

  // override the now nested title sections to be smaller
  .card .titles.t3 {
    @include titles(5)
  }
}
