
:root {
  //------------------------------------------------------------------------------
  // ORGANISM STYLING
  --organism--flex-direction: column;
  --organism--color: var(--font-color--base);
  --organism--border: none;
  --organism--border-radius: 0;
  --organism--background: transparent;
  --organism--width: unset;
  --organism--min-width: unset;
  --organism--max-width: unset;
  --organism--height: unset;
  --organism--min-height: unset;
  --organism--max-height: unset;
  --organism--padding: 0;
  --organism--margin: 0;
  --organism--box-shadow: none;
  --organism-header--height: var(--header--height);
  --organism-footer--height: var(--footer--height);
}
