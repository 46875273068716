input,
select,
textarea {
  &.ng-invalid.ng-touched {
    border-color: var(--alert--danger);
    color: var(--alert--danger);
    box-shadow: 0 0 .2rem 0 var(--color--danger);
    &:not(:focus)::placeholder {
      color: var(--alert--danger);
    }

    & + .alert--paired {
      transform: translateY(-.2rem);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

  }
  &.ng-invalid.ng-touched:focus {
    box-shadow: 0 0 0.3rem .1rem var(--alert--danger), 0 0 0rem .1rem var(--alert--danger);
  }
}
