$overflows: auto hidden visible hidden;
$axes: both x y;

@each $axis in $axes {
  .u-overflow {
    @if $axis == both {
      @each $overflow in $overflows {
        &--#{$overflow} {
          overflow: $overflow;
        }
      }
    } @else {
      @each $overflow in $overflows {
        &-#{$axis}--#{$overflow} {
          overflow-#{$axis}: $overflow;
        }
      }
    }
  }
}

//------------------------------------------------------------------------------
// Overflow Shadow

:root {
  --overflowShadow--bg--color: #ececee;
}

// Left-Right
.u-overflow-shadow--x {
  background:
    linear-gradient(to right, var(--overflowShadow--bg--color) 30%, rgba(255,255,255,0)),
    linear-gradient(to right, rgba(255,255,255,0), var(--overflowShadow--bg--color) 70%) 0 100%,
    radial-gradient(farthest-side at 0% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
  background-repeat: no-repeat;
  background-color: var(--overflowShadow--bg--color); // need to flex
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
}

// Up-Down
.u-overflow-shadow--y {
  background:
    linear-gradient(var(--overflowShadow--bg--color) 30%, rgba(255,255,255,0)),
    linear-gradient(rgba(255,255,255,0), var(--overflowShadow--bg--color) 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
  background-repeat: no-repeat;
  background-color: var(--overflowShadow--bg--color); // need to flex
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
}

//------------------------------------------------------------------------------
// Keep Scrolling, hide scrollbar

.u-scrollbar--hide {
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE 10+
  &::-webkit-scrollbar {
    width: 0rem;
    height: 0rem;
    background: transparent; // Chrome/Safari/Webkit
  }
  // disable children from inheriting
  * {
    scrollbar-width: initial;
    -ms-overflow-style: initial;
    &::-webkit-scrollbar {
      width: initial;
      height: initial;
      background: initial;
    }
  }
}