
:root {
  --list-item--padding--tb: var(--padding--tb);
  --list-item--padding--lr: var(--padding--lr);
  --list-item-row--gutter: .2rem;
  --list-item-field--gutter: .5rem; // space between two fields in a row
}

.list {
    display: block;
    .list-item {

      &:not(:first-of-type) { border-top: $border--black--lighter; }

      &:not(.is-active) {
        @include hoverEffect(color);
        min-height: var(--minHitArea); }

    }
}

.list-item {
  @include transition;
  @include flex(column);
  padding: var(--list-item--padding--tb) var(--list-item--padding--lr);
  transform: scale(1);

  &[class*="row"] {
    flex-direction: row;
  }
}

// vertically centering text within the rows when needed
.list-item,
.list-item-main {
  *[class*="row"]:only-of-type:not([class*="u-flex--"]) {
    margin-top: auto;
    margin-bottom: auto; }
}


//------------------------------------------------------------------------------
// set up mixins for the default field styles and row styles

@mixin field--primary {
  font-weight: $font-weight--bold;
  @include fontSize(default);
}

@mixin field--secondary {
  @include opacity(light);
  @include fontSize(small);
  line-height: 1.4;
}

@mixin field--tertiary {
  @include opacity(lighter);
  @include fontSize(small);
  line-height: 1.1;
}

@mixin list-item-row--primary {
  @include fontSize(default);
  & > .field:nth-of-type(1):not(.formatting--none) { // top left
    @include field--primary; }
}

@mixin list-item-row--secondary {
  @include fontSize(small);
  & > .field:not(.formatting--none) {
    @include field--secondary; }
}

@mixin list-item-row--tertiary {
@include fontSize(small);
  & > .field:not(.formatting--none) {
    @include field--tertiary; }
}

//------------------------------------------------------------------------------
// Set up classes to apply primary and secondary styles where structure doesnt inherit or fit standards
// - IE - there is a en extra row above or something else weird

.list-item > *[class*="row"].list-item-row--primary,
.list-item-row--primary,
.fields--primary > .field:not(.formatting--none)   { @include list-item-row--primary   }
.list-item > *[class*="row"].list-item-row--secondary,
.list-item-row--secondary,
.fields--secondary > .field:not(.formatting--none) { @include list-item-row--secondary }
.list-item > *[class*="row"].list-item-row--tertiary,
.list-item-row--tertiary,
.fields--tertiary > .field:not(.formatting--none)  { @include list-item-row--tertiary  }

.list-item > *[class*="row"] > .field.field--primary.field--primary,
.field--primary { @include field--primary;  }
.list-item > *[class*="row"] > .field.field--secondary.field--secondary,
.field--secondary { @include field--secondary; }
.list-item > *[class*="row"] > .field.field--tertiary.field--tertiary,
.field--tertiary  { @include field--tertiary;  }


//------------------------------------------------------------------------------
// default layout types

.list-item {

  //------------
  // On list-items that we dont kill the styles on (formatting--none),
  // we target every row that isn't the first row, or marked primary - and make them secondary
  &:not(.formatting--none) > *[class*="row"]:not(:nth-of-type(1)):not(.list-item-row--primary):not(.list-item-row--tertiary),
  &:not(.formatting--none) > .list-item-main > *[class*="row"]:not(:nth-of-type(1)):not(.list-item-row--primary):not(.list-item-row--tertiary) {
    @include list-item-row--secondary;
    margin-top: var(--list-item-row--gutter); }

  //------------
  // this is adding a "gutter" between fields when we dont use formatting none on the list-item or field
  &:not(.formatting--none) > *[class*="row"] > .field:not(:last-child):not(.formatting--none) {
    margin: 0 var(--list-item-field--gutter) 0 0; }

  //------------
  &:not(.formatting--none),
  &--1,
  &:not(.formatting--none) .list-item-main { // modified so that mod class is not required for default styling
    // "DEFAULT" list-item layout -
    // modifier class to go on the list-item that will automatically apply
    // what we consider "default" styles to the four quadrant fields
    // NOTE: truncation should still be handled via utilities

    // Bold first field ------------------- normal second
    // light third ------------------------ light 4

    // FIRST ROW - add field text styles
    & > *[class*="row"]:nth-of-type(1) { // Top (first) row
      @include list-item-row--primary; }

    // Every row except first gets secondary styling
    & > *[class*="row"]:not(:nth-of-type(1)) {
        @include list-item-row--secondary; }

    // FIELDS - truncate by default, unless specified
    & > *[class*="row"] > .field:not(.u-text-wrap) {
      @include truncate; }

    //First fields we add flex grow
    & > *[class*="row"] > .field:nth-of-type(1) {
        flex-grow: 1; }

  }

}

//------------------------------------------------------------------------------
// STATE styling for list-items

.list-item {

  &.is-active {
    background-color: var(--active, var(--interaction));
    color: var(--active--contrast, var(--interaction--contrast)); }

  &.is-unread:not(.is-active) {
    position: relative;

    &:before {
      content: "";
      width: .4rem;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: var(--interaction); }

    & > *[class*="row"]:nth-of-type(1) {
      color: var(--interaction--dark); }

  }
}


//------------------------------------------------------------------------------
// Types of Lists

//------------------------------------------------------------------------------
// Static (no hover or linking) list

.list-item--static,
.list.list--static .list-item {
  @include hoverEffect(none); }

//------------------------------------------------------------------------------
// Floating list

.list--floating {

  .list-item {
    @include box-shadow(1);
    border: 0 !important;
    border-radius: var(--border-radius);
    margin-bottom: var(--space);
    overflow: hidden; }

  .list-item.is-active {
    box-shadow: 0 0 .8rem .1rem var(--interaction--lightest); }

  .list-item:not(.is-active):hover {
    box-shadow: 0 0 .8rem .1rem var(--interaction--lightest); }

  .list-item.is-unread {
    box-shadow: 0 0 .5rem 0 var(--interaction--lighter); }

}


//------------------------------------------------------------------------------
// list-option

// made do be a container element for checkbox, radio, toggle
// the list-item ALTERNATIVE (NOT A MODIFIER) for items that are only
// checkbox, radio, toggles within.
// This doesnt have the same padding (which would cause items to be super tall)
// min hit area, hover effect, etc that list-item does.
// Those things should (and do) happen on the input + label inside of the list-option

.list-option {
  .list > &:not(:first-of-type) { border-top: $border--black--lighter; }

  padding: 0 var(--list-item--padding--lr); // padding comes from input + label within

  & > input[type="checkbox"] + label { padding-right: 0 }
}


//------------------------------------------------------------------------------
// list-a , just an anchor as a list item

.list-a {
  @include flex;
  @include transition;
  min-height: var(--minHitArea--small);
  align-items: center;

  &.is-active {
    font-weight: $font-weight--black;
    color: var(--active, var(--interaction));
  }
}
