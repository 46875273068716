@use 'sass:math';

input[type="checkbox"],
input[type="radio"] {
  display: inline-block; // it still technically needs to be on the page in order to tab to it
  float: left;
  position: absolute;
  opacity: 0; // hide actual checkbox, use CSS pseudo elements
  width: 0 !important;
  height: 0 !important;
  min-height: 0;
  margin: 0;
  padding: 0;

  & + label {
    @include wordWrap();
    @include flex;
    cursor: pointer;
    align-items: baseline;
    position: relative;
    min-height: var(--checkbox--size);
    padding: var(--checkbox-label--padding--tb) var(--checkbox-label--padding--lr);
    padding-left: calc(var(--checkbox--size) + var(--checkbox-label--padding--lr) );


    &:before { // BACKGROUND ELEMENT -- circle/square
      content: '';
      position: absolute;
      left: .1rem;
      top: var(--checkbox-label--padding--tb);
      display: block;
      height: var(--checkbox--size);
      width: var(--checkbox--size);
      border-radius: calc( var(--checkbox--size) * .2);
      background-color: transparent;
      border: solid .2rem var(--interaction);
      box-sizing: border-box;
      transition: all ease-in-out $checkbox--animationSpeed;
      box-shadow: 0 0 0 0 transparent; }
    &:after { // FOREGROUND ELEMENT
      box-sizing: initial; }

  }

  // :FOCUS and :HOVER -- RADIO AND CHECKBOX
  &:not(:disabled):focus + label:before,
  &:not(:disabled):hover + label:before,
  &:not(:disabled) + label:focus:before,
  &:not(:disabled) + label:hover:before {
    // BACKGROUND ELEMENT on :focus and :hover
    background-color: var(--interaction--lighter);
  }

  // :FOCUS ONLY (TAB) -- RADIO AND CHECKBOX
  &:focus + label:before,
  & + label:focus:before {
    // BACKGROUND ELEMENT on :focus
    box-shadow: 0 0 .1rem .1rem var(--interaction--light);
  }

  // :CHECKED -- RADIO AND CHECKBOX
  &:checked + label {
    &:before { // BACKGROUND ELEMENT -- circle/square
      animation: boxShadow--wripple $checkbox--animationSpeed ease-in-out forwards;
      background-color: var(--interaction); }
    &:after { // FOREGROUND ELEMENT -- checkmark
      transform: rotate(45deg) scale(1); }
  }

  // DISABLED -- RADIO AND CHECKBOX
  &:disabled + label {
    opacity: .6;
    cursor: not-allowed;
    &:before { border-color: var(--interaction--lighter); }
  }

  // NO TEXT / LABEL
  &.is-standalone {
    & + label {
      --checkbox-label--padding--tb: .1rem;
      &:not([class*='padding-']) {
        padding: 0;
      }
    }
  }

} // CHECKBOX & RADIO


//------------------------------------------------------------------------------
// CHECKBOX SPECIFIC

input[type="checkbox"] {

  // FOREGROUND ELEMENT -- checkmark
  & + label:after {
    content: '';
    width: calc( var(--checkbox--size) * .2 ) ;
    height: calc( var(--checkbox--size) * .55) ;
    border-right: solid calc( var(--checkbox--size) * .13)  #FFF;
    border-bottom: solid calc( var(--checkbox--size) * .13)  #FFF;
    box-sizing: content-box;
    position: absolute;
    left: calc( var(--checkbox--size) * .4);
    top: calc( var(--checkbox--size) * .1 + var(--checkbox-label--padding--tb));
    transition: all ease-in-out .1s;
    transform: rotateX(-90deg) scale(.8); }

  // BACKGROUND ELEMENT of :CHECKED item on :hover
  &:checked:hover + label:before {
    background-color: var(--interaction--light);
    border: solid .1rem transparent; }

  // :CHECKED :FOCUS -- CHECKBOX
  &:checked:focus + label,
  &:checked + label:focus  {
    &:before {
      // BACKGROUND ELEMENT on :focus
      border-color: var(--interaction--darker);
      background-color: var(--interaction--dark); }
  }

} // CHECKBOX ONLY


//------------------------------------------------------------------------------
// RADIO SPECIFIC

input[type="radio"] {

  // BACKGROUND ELEMENT for radio
  & + label:before {
    border-radius: calc( var(--checkbox--size) * .5); }

  // BACKGROUND ELEMENT :checked -- radio
  &:checked + label:before {
    background-color: var(--interaction);
    background: var(--interaction);
    background: -moz-radial-gradient(center, ellipse cover, var(--interaction) 35%, var(--interaction--lighter) 45%);
    background: -webkit-radial-gradient(center, ellipse cover, var(--interaction) 35%, var(--interaction--lighter) 45%);
    background: radial-gradient(ellipse at center, var(--interaction) 35%, var(--interaction--lighter) 45%); }

  // :CHECKED :FOCUS -- RADIO
  &:checked:focus + label,
  &:checked + label:focus  {
    &:before {
      // BACKGROUND ELEMENT on :focus
      background-color: var(--interaction--lighter);
      border-color: var(--interaction--dark); }
  }

} // RADIO ONLY


//------------------------------------------------------------------------------
// TOGGLE VERSION

$toggle--size: 2.4rem;
$toggle--background-color: $background-color--black--4;
$toggle--slider-color: #FFF;

input[type="checkbox"].toggle,
input[type="radio"].toggle {

  min-height: 0;

  & + label {
      padding-left: 0;
      padding-right: 5rem;
      &:before { // BACKGROUND ELEMENT
          @include position(absolute, math.div($toggle--size, 4), 0, initial , initial);
          height: math.div($toggle--size, 1.7);
          width: $toggle--size * 1.7;
          border-radius: math.div($toggle--size, 2);
          background-color: $toggle--background-color;
          border: none;
      }
      &:after { // FOREGROUND ELEMENT
         @include position(absolute, 0, math.div($toggle--size, 1.2), initial , initial);
          box-shadow: 0 0rem .1rem .1rem rgba(0,0,0,.2), 0 0rem 0 .1rem rgba(0,0,0,.1);
          height: $toggle--size;
          width: $toggle--size;
          background-color: $toggle--slider-color;
          border-radius: $toggle--size;
          border: none;
          transform: none;
    }
  }

  // HOVER
  &:not(:checked) + label:hover {
    &:before { // BACKGROUND ELEMENT
        background-color: darken($toggle--background-color, 20);
    }
    &:after { // FOREGROUND ELEMENT
        background-color: $toggle--slider-color;
    }
  }

  // FOCUS
    &:not(:checked):focus + label,
    &:not(:checked) + label:focus {
      &:before { // BACKGROUND ELEMENT
          background-color: darken($toggle--background-color, 20);
      }
  }

  // CHECKED
  &:checked + label {
        &:before { // BACKGROUND ELEMENT
            background-color: var(--interaction--light);
            border: none;
        }
        &:after { // FOREGROUND ELEMENT
          @include position(absolute, 0, 0, initial , initial);
          background-color: var(--interaction);
          box-shadow: 0 0 .1rem 0 var(--interaction--lightest);
      }
  } // toggle on

  // FOCUS WHEN CHECKED
    &:checked:focus + label,
    &:checked + label:focus {
      &:before { // BACKGROUND ELEMENT
          background-color: var(--interaction--dark)
      }
  }

}


//------------------------------------------------------------------------------
input.is-large {

  & + label {
    --checkbox--size: 2.8rem;
  }


  &[type="checkbox"] + label:before,
  &[type="radio"] + label:before {
    top: calc( var(--checkbox-label--padding--tb) - var(--checkbox--size) * .15 );
  }

  &[type="checkbox"]:checked + label:after {
    top: calc( var(--checkbox-label--padding--tb) - var(--checkbox--size) * .06);
  }

}
