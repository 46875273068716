//------------------------------------------------------------------------------

.outletContainer {
  @include flex;
  flex-grow: 1;
  overflow: hidden;

  & > router-outlet + * {
    width: 100%;

    &:not(.column) {
      @include flex;
    }

  }
}


.outlet--column,
.outlet--row {
  & + * {
    @include flex(column);
    flex-grow: 1;
    overflow: hidden;
  }
}

.outlet--row {
  & + * {
    @include flex(row);
  }
}
