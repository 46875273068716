$types: (
  light: "white", // white would be the class modifier on the end of the border class
  dark: "black"
  // for dark borders, we dont use a modifier, so the name doesnt matter, we dont apply it
);
$sides: (
  all: "all",
  top: "top",
  right: "right",
  bottom: "bottom",
  left: "left",
);
$borderColors--dark: (
  5: $border--black--lightest,
  4: $border--black--lighter,
  3: $border--black--light,
  2: $border--black--dark,
  1: $border--black--darker,
  0: $border--black--solid,
  default: $border--black--default,
  none: $border--none
);
$borderColors--light: (
  0: $border--white--solid,
  5: $border--white--lightest,
  4: $border--white--lighter,
  3: $border--white--light,
  2: $border--white--dark,
  1: $border--white--darker,
  default: $border--white--default,
  none: $border--none
);
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// CREATION LOOP
@each $colorBase, $typeClassName in $types {
  @if($colorBase==light) {
    @each $side, $sides in $sides {
      @each $modifier--light, $border--light in $borderColors--light {
        @if($border--light == "none") {
          // handled on dark
        } @else {
          @if($sides=="all") {
            @if($modifier--light==default) {
              .border--#{$typeClassName} {
                border: $border--light;
              }

              .borders--#{$typeClassName} > * {
                border: $border--light;
              }
            } @else {
              .border--#{$modifier--light}--#{$typeClassName} {
                border: $border--light;
              }

              .borders--#{$modifier--light}--#{$typeClassName} > * {
                border: $border--light;
              }
            }
          } @else {
            @if($modifier--light==default) {
              .border-#{$sides}--#{$typeClassName} {
                border-#{$sides}: $border--light;
              }

              .borders-#{$sides}--#{$typeClassName} > * {
                border-#{$sides}: $border--light;
              }
            } @else {
              .border-#{$sides}--#{$modifier--light}--#{$typeClassName} {
                border-#{$sides}: $border--light;
              }

              .borders-#{$sides}--#{$modifier--light}--#{$typeClassName} > * {
                border-#{$sides}: $border--light;
              }
            }
          }
        }
      }
    }
  } @else {
    @each $side, $sides in $sides {
      @each $modifier--dark, $border--dark in $borderColors--dark {
        @if($border--dark=="none") {
          .border-#{$sides}--#{$border--dark},
          .borders-#{$sides}--#{$border--dark} > * {
            border-#{$sides}: $border--dark !important;
          }
        } @else {
          @if($sides=="all") {
            @if($modifier--dark==default) {
              .border {
                border: $border--dark;
              }

              .borders > * {
                border: $border--dark;
              }
            } @else {
              .border--#{$modifier--dark} {
                border: $border--dark;
              }

              .borders--#{$modifier--dark} > * {
                border: $border--dark;
              }
            }
          } @else {
            @if($modifier--dark==default) {
              .border-#{$sides} {
                border-#{$sides}: $border--dark;
              }

              .borders-#{$sides} > * {
                border-#{$sides}: $border--dark;
              }
            } @else {
              .border-#{$sides}--#{$modifier--dark} {
                border-#{$sides}: $border--dark;
              }

              .borders-#{$sides}--#{$modifier--dark} > * {
                border-#{$sides}: $border--dark;
              }
            }
          }
        }
      }
    }
  }
}

// Border None
.border--none {
  border: none;
}
