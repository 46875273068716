.panel {
  --organism--background: var(--panel--background, var(--organism--background--default));
  --organism--color: var(--panel--color, var(--organism--color--default));
  --organism--min-width: var(--panel--width);
  --organism--width: var(--panel--width);
  --organism--max-width: var(--panel--width);
  @include transition;
  &.is-closed {
    --organism--min-width: 0;
    --organism--width: 0;
    --organism--max-width: 0;
  }
}

.panel > .header .titles:not(.t1):not(.t2):not(.t3):not(.t4):not(.t5):not(.t6)  {
  @include titles(4);
}
