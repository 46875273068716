table {
  --table-th--padding: var(--space) var(--space);
  --table-th--background-color: transparent;
  --table-td--padding: var(--space) var(--space);
  --table-td--background-color: transparent;
}

table {

  thead {
    th:not(:empty) {
      border-bottom: $border--black--default;
      border-width: 2px;
    }
  }

  tr {
    border-bottom: $border--black--lighter;
    th:not(:empty):not(:last-child) {
      border-right: $border--black--light;
      border-right-width: 1px;
    }
  }

  th {
    background-color: var(--table-th--background-color);
    padding: var(--table-th--padding);
    font-weight: $font-weight--bold;
    &:empty {
      --table-th--background-color: transparent;
    }
  }

  td {
    background-color: var(--table-td--background-color);
    padding: var(--table-td--padding);
  }

}


//------------------------------------------------------------------------------

table.table--bordered {

  border: $border--black--default;
  border-width: 1px;

  thead {
    border-bottom: $border--black--default;
    border-width: 2px;
  }

  tr {
    border-bottom: $border--black--light;
    border-width: 2px;
  }

  th {
    &:not(:last-child) {
      border-right: $border--black--light;
    }
  }

  td {
    border-right: $border--black--lighter;
  }


}

//------------------------------------------------------------------------------

table.table--2 {
  --table-td--background-color: #{$background-color--black--5};
  border-radius: var(--border-radius);
  overflow: hidden;

  thead,
  thead th,
  tr th {
    border: none;
  }

  tr {
    border-bottom: solid .3rem var(--body--background-color);
  }

  th {
    @include header(6);
    @include opacity(light);
  }

  td {
    border-right: $border--white--dark;
  }


}


//------------------------------------------------------------------------------

table.table--tight {
  --table-th--padding: var(--space--small);
  --table-td--padding: var(--space--small);
}

table.table--tighter {
  --table-th--padding: var(--space--smaller);
  --table-td--padding: var(--space--smaller);
}
