.navbar {
  --organism--flex-direction: row;
  --organism--background: var(--navbar--background, var(--interaction));
  --organism--color: var(--navbar--color, var(--interaction--contrast));
  --organism--min-height: var(--navbar--height, var(--organism--color--default));
  --organism--height: var(--navbar--height);
  --organism--padding: var(--space--small) var(--space);
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
}


.navbar--pageTabs {
  --navbar--height: 6rem;
  --tab--height: 5rem;
  --input--border-radius: .8rem;

  padding: 0 var(--space--small);
  align-items: flex-end;

  .tabs--page .tab {
    border: none;
    position: relative;
    overflow: visible;

    &:hover,
    &:focus {
      background-color: rgba(255,255,255,.1);
      color: #FFF
    }

    // Tab corners
    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 1rem;
      height: 1rem;
      bottom: -1rem;
      transition: all .5s ease;
    }

    &:before {
      left: -1rem;
      background: radial-gradient(circle at 0% 0%, rgba(255, 0, 0, 0) 1rem, var(--body--background-color) 1.1rem)
    }

    &:after {
      right: -1rem;
      background: radial-gradient(circle at 100% 0%, rgba(255, 0, 0, 0) 1rem, var(--body--background-color) 1.1rem)
    }

    // Active
    &.is-active {
      background-color: var(--body--background-color);
      color: var(--interaction);
      position: sticky;
      left: -2.2rem;
      z-index: 1;

      &:before,
      &:after {
        bottom: 0;
      }

    }
  }
}
