%break {
  border-top: $border--black--default;
  margin: var(--space--large) 0;
}

.u-break {
  @extend %break;

  &--smallest {
    @extend %break;
    margin: var(--space--smallest) 0; }

  &--smaller {
    @extend %break;
    margin: var(--space--smaller) 0; }

  &--small {
    @extend %break;
    margin: var(--space--small) 0; }

  &--large {
    @extend %break;
    margin: var(--space--large) 0; }

  &--larger {
    @extend %break;
    margin: var(--space--larger) 0; }

  &--largest {
    @extend %break;
    margin: var(--space--largest) 0; }

}
