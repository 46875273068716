
%h1 {
  @include header(1); }

%h2 {
  @include header(2); }

%h3 {
  @include header(3); }

%h4 {
  @include header(4); }

%h5 {
  @include header(5); }

%h6 {
  @include header(6); }

//------------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  color: var(--font-color--header, --font-color--base);
  &:first-child:not([class*="margin-top"]) {
    margin-top: 0;
  }
}


//------------------------------------------------------------------------------

h1 {
 @extend %h1;
 margin-top: var(--space--largest);
 margin-bottom: var(--space--larger);
}

h2 {
  @extend %h2;
  margin-top: var(--space--largest);
  margin-bottom: var(--space--larger); }

h3 {
  @extend %h3;
  margin-top: var(--space--largest);
  margin-bottom: var(--space--larger); }

h4 {
  @extend %h4;
  margin-top: var(--space--larger);
  margin-bottom: var(--space--large); }

h5 {
  @extend %h5;
  margin-top: var(--space--larger);
  margin-bottom: var(--space--small); }

h6 {
  @extend %h6;
  margin-top: var(--space--larger);
  margin-bottom: var(--space--small); }


//------------------------------------------------------------------------------

.d1,
.d2,
.d3,
.d4,
.d5,
.d6 {
  font-family: $font-family--display;
  color: var(--font-color--display, --font-color--base);
}

.d1 {
  font-size: calc( var(--font-size--base) * 3.5);
  font-weight: 900;
  text-transform: uppercase; }

.d2 {
  font-size: calc( var(--font-size--base) * 3);
  font-weight: 900; }

.d3 {
  font-size: calc( var(--font-size--base) * 1.5 + .4rem);
  text-transform: uppercase;
  font-weight: 900; }

.d4 {
  font-size: calc( var(--font-size--base) * 1.5 ) }

.d5 {
  font-size: calc( var(--font-size--base));
  text-transform: uppercase;
  font-weight: 900;}

.d6 {
  font-size: calc( var(--font-size--base) - 0 );
  text-transform: uppercase; }

//------------------------------------------------------------------------------


.lead--larger,
.lead--large,
.lead,
.lead--small {
  // font-weight: $font-weight--light;
  color: var(--font-color--base);
  @include opacity(lighter);
  line-height: calc( var(--line-height--base) - .2 );
}

.lead--larger {
  font-size: calc( var(--font-size--base) * 2.5);
  margin-bottom: var(--space--larger);
}

.lead--large {
  font-size: calc( var(--font-size--base) * 2);
  margin-bottom: var(--space--large);
}

.lead {
  font-size: calc( var(--font-size--base) * 1.5 + .2rem);
  margin-bottom: var(--space--large);
}

.lead--small {
  font-size: calc( var(--font-size--base) + .4rem );
  margin-bottom: var(--space);
}

//------------------------------------------------------------------------------

.helperText {
  @include opacity(lighter);
  font-size: var(--font-size--small);
  line-height: var(--font-size--small) + .2rem;
}



//------------------------------------------------------------------------------

.subheader {
  @include header(5);
  background-color: rgb(215, 222, 230);
  padding: var(--space--small) var(--space)
}

.subheader--plain {
  @include header(5);
  color: #98aeb3;
}


//------------------------------------------------------------------------------

p {
  font-size: var(--font-size--base);
  line-height: 1.5;
  margin-bottom: var(--space);
}

a {
  color: var(--interaction);
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    color: var(--interaction--darker);
  }
}

.label {
  @include flex;
  @include opacity(lighter);
  font-size: var(--font-size--smallest);
  padding: 0 0 var(--space--smaller) 0;
  text-transform: uppercase;
  font-weight: $font-weight--bold;
}

//------------------------------------------------------------------------------

.emptyText {
  @include opacity(lightest);
  font-size: var(--font-size--smallest);
  font-style: italic;
  &.u-center {
    align-self: center;
    justify-self: center;
  }
}
