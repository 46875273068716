.badge {
  padding: var(--space--smallest) var(--space--smaller);
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1;
  border-radius: var(--border-radius);
  flex-shrink: 0; }

.badge:not([class*='u-color']) {
  background-color: var(--alert--danger);
  color: var(--alert--danger--contrast); }


*[data-badge],
*[data-alertBadge] {
  position: relative; }

*[data-badge]:before,
*[data-alertBadge]:before {
  @include flex;
  position: absolute;
  background-color: var(--alert--danger);
  color: var(--alert--danger--contrast);
  top: var(--space--smallest);
  left: 0;
  z-index: 0;
  padding: var(--space--smallest) var(--space--small);
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  font-weight: $font-weight--bold;
  font-size: 1.3rem;
  line-height: 1; }

*[data-badge]:before {
  content: attr(data-badge);}
*[data-alertBadge]:before {
  content: attr(data-alertBadge); }

.badge--floating::before {
  border-radius: 5rem !important;
  right: var(--space--smallest);
  left: unset !important; }

.badges {
  @include flex;

  & > .badge:not(:only-of-type):not([class*="margin-"]) {
    margin-right: .2rem;
  }
}
