// A place to put possible edits/updates to existing Magma classes

.badge.badge--spacer {
  background-color: transparent;
  color: transparent;
  visibility: hidden;
  &::after {
    content: "\00a0";
    font-family: monospace;
  }
}

//------------------------------------------------------------------------------

.list-item--complex {
    @include flex;
    padding: 0 0;

    .list > &.list-item:not(.is-active):not(.list-item--singleHitArea) {
      // list-item--singleHitArea makes list-item-complex still have a single hover, without it, hover effect is applied to inside areas individually (-action, -main)
      // Note: list-item-complex should be avoided in layouts that dont have multiple hit areas. But, we still build modifiers incase
      // Instead of list-item-complex, try resetting the list-item to a .row and using other modifiers and interior elements (ie - list-item-main)
      @include hoverEffect(none);

      .list-item-main:not(.u-hoverEffect--none),
      .list-item-action {
        @include hoverEffect(color);

        // rarely, there may be a main area inside another main area for formatting reasons
        // we dont want to double the hover effect
        .list-item-main { @include hoverEffect(none); }
      }
    }

}

.list-item--complex > *,
.list-item[class*="row"] > * {
  // "smart" add padding to interior elements
  // full var on left and right first and left children
  // half var on middle pieces

  &:not([class*="padding-left"]):not(.padding--none) {
    padding-left: calc( var(--list-item--padding--lr) / 2)
  }

  &:not([class*="padding-right"]):not(.padding--none) {
    padding-right: calc( var(--list-item--padding--lr) / 2)
  }

  &:not([class*="padding-top"]):not(.padding--none) {
    padding-top: var(--list-item--padding--tb)
  }

  &:not([class*="padding-bottom"]):not(.padding--none) {
    padding-bottom: var(--list-item--padding--tb)
  }

  &:first-child:not([class*="padding-left"]):not(.padding--none) {
    padding-left: var(--list-item--padding--lr);
  }

  &:last-child:not([class*="padding-right"]):not(.padding--none):not(.list-item-action) {
    padding-right: var(--list-item--padding--lr)
  }

  &:last-child:not([class*="padding-right"]):not([class*="padding-left"]):not(.padding--none).list-item-action {
    padding-left: var(--list-item--padding--lr);
    padding-right: var(--list-item--padding--lr);
  }
}

.list-item[class*="row"] {
  padding: 0;
}


//------------------------------------------------------------------------------
// Main interior named building blocks for list-items

.list-item-main {
    @include flex(column);
    flex-grow: 1;
    min-height: 0;
    min-width: 0;

    &[class*="row"] {
      flex-flow: row;
    }
}

.list-item-avi {
    @include flex;
    flex-shrink: 0;
    justify-content: center;
}

* + .list-item-block:not(.border--none):not(:first-child),
* + .list-item-action:not(.border--none):not(:first-child) {
  border-left: $border--black--lighter;
}

.list-item-icon {
  @include flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.list-item-action {
  @include flex;
  align-items: center;
  justify-content: center;
  @include minHit;
  flex-shrink: 0;
}
