.dropdown {
  --organism--background: var(--dropdown--background, var(--organism--background--default));
  --organism--color: var(--dropdown--color, var(--organism--color--default));
  --organism--box-shadow: 0 0 .1rem 0 rgba(0,0,0,.25), 0 .1rem .5rem .1rem rgba(0,0,0,.1), 0 1.5rem 2rem 0 rgba(0,0,0,.3);
  --organism--border-radius: var(--border-radius);
}

//------------------------------------------------------------------------------

.dropdown > .header .titles:not(.t1):not(.t2):not(.t3):not(.t4):not(.t5):not(.t6)  {
  @include titles(4);
}

//------------------------------------------------------------------------------

.dropdown .footer {
  padding-bottom: var(--space);
  &:not(.border) {
    border-top: none;
  }
}
