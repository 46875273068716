/* Add media specific displays */
.u-display--none {
  display: none;
}

.u-display--none {
  display: none;
}

.u-visibility--hidden {
  all: unset;
  height: 0;
  width: 0;
  visibility: hidden;
}

// responsive

@media (max-width: $screen--larger) {
  .u-display--none--larger { display: none; }
}

@media (min-width: $screen--larger) {
  .u-display--larger { display: none; }
}

@media (max-width: $screen--mobile) {
  .u-display--none--mobile { display: none; }
}

@media (min-width: $screen--mobile) {
  .u-display--mobile { display: none; }
}
