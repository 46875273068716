.barGraph {
  height: 2rem;
  display: flex;
  border-radius: var(--border-radius);
  background-color: $background-color--black--4;
  .bar {
    align-self: stretch;
    background-color: #FFF;
  }
}
