
.group {
  @include flex(column);
  @include wordWrap;

  .label {
      @include opacity(light);
      flex-shrink: 0;
      padding-bottom: var(--space--smaller);
      text-transform: capitalize;
    }

    .label-helper {
      @include opacity(light);
      margin-left: var(--space--smaller);
      font-weight: $font-weight--normal;
    }

}

//------------------------------------------------------------------------------
// Indicate empty field with '--'

.group--indicateEmpty > .field:empty:after,
.groups--indicateEmpty > .group > .field:empty:after {
  content: "--";
  @include opacity(lightest);
  letter-spacing: .1rem;
}


//------------------------------------------------------------------------------
// Table version

.groups--table {
  display: table;
  width: 100%;

  .group {
    display: table-row;
    border-bottom: $border--black--light;

    .label,
    .field {
      display: table-cell;}
  }

}

//------------------------------------------------------------------------------
// Required Groups in forms

.group.is-required {
  .label {
    font-weight: $font-weight--bold;
  }
  .label:after {
    content: '*';
    @include opacity(lighter);
    font-size: var(--font-size--small);
    font-weight: $font-weight--light;
    align-self: start;
    transform: translateY(-.3rem);
    margin-left: 0;
    line-height: 1;
  }
}

//------------------------------------------------------------------------------
// DEFAULT FORMATTING VIA MODIFIER CLASSES

.group {

    //--------------------------------------------------------------------------
    // layout 1

    // LABEL
    // large field

    .groups--1 > &,
    &.group--1 {

      .label {
        padding-bottom: .3rem;
      }
      
        .field:not(input) {
            @include fontSize(large);
            font-weight: $font-weight--bold;
            line-height: 1.15;
            margin-top: .2rem; }
    }


    //--------------------------------------------------------------------------
    // layout 2

    // LABEL field

    .groups--2 > &,
    &.group--2 {
      flex-flow: row;
      align-items: baseline;

      &.row--verticalCenter,
      &.u-align-items--center {
        align-items: center;
      }

      .label {
        padding-bottom: 0;
        @include preserve;
        margin-right: var(--space--small); }

    }


    //--------------------------------------------------------------------------
    // layout 3

    // LABEL field

    .groups--3 > &,
    &.group--3 {
      flex-flow: row;

      .label {
          @include preserve;
          opacity: 1;
          font-weight: bold;
          text-transform: none;
          font-size: inherit;
          padding: 0 var(--space) 0 0; }

    }


    //--------------------------------------------------------------------------
    // layout 4

    // field  LABEL
    .groups--4 > &,
    &.group--4 {
      flex-flow: row;
      align-items: baseline;

      .label {
        @include preserve;
        order: 2;

        &:not([class*="margin-left"]) {
          margin-left: var(--space);
        }
      }

    }


    //--------------------------------------------------------------------------
    // layout 5

    // field ---------------- LABEL

    .groups--5 > &,
    &.group--5 {
      flex-flow: row;
      align-items: baseline;

      &:not(:first-child) {
        border-top: $border--black--lighter;
      }

      .field {
        flex-grow: 1;
        padding-bottom: 0; }

      .label {
          @include preserve;
          order: 2;
          margin-left: var(--space);
          padding-bottom: 0; }

    }



    //--------------------------------------------------------------------------
    // layout 6

    // label: FIELD

    .groups--6 > &,
    &.group--6 {
      flex-flow: row;
      flex-wrap: wrap;
      align-items: baseline;

      .field:not(input) {
        font-weight: $font-weight--bold;
      }

      .label {
        opacity: 1;
        font-weight: normal;
        text-transform: capitalize;
        font-size: inherit;
        padding-bottom: 0;
        &:after {
          content: ':';
          margin-right: var(--space--smaller)
        }
          @include preserve; }

    }

    //--------------------------------------------------------------------------
    // layout 6

    //     label: field

    .groups--7 > &,
    &.group--7 {
      flex-flow: row;
      align-items: center;

      .field:not(input) {
        font-weight: $font-weight--bold;
      }

      .label {
        display: flex;
        justify-content: flex-end;
        opacity: 1;
        font-weight: normal;
        text-transform: capitalize;
        font-size: inherit;
        padding-bottom: 0;
        text-align: right;
        &:after {
          content: ':';
          margin: 0 var(--space--smallest);
          align-self: center;
        }
          @include preserve; }

    }
}
