%row {
  @include flex;
}

////////////////////////////////////////////////////////////////////////////////
// Primary Flex Structures

.row {
  @extend %row }

.row--wrap {
  @include flex(row, wrap); }

.column {
  @include flex(column); }

.column--wrap {
  @include flex(column, wrap); }


////////////////////////////////////////////////////////////////////////////////
// Allignment Modifiers

.row--top {
  @extend %row;
  align-items: flex-start; }

.row--bottom {
  @extend %row;
  align-items: flex-end; }

.row--baseline {
  @extend %row;
  align-items: baseline; }

.row--right {
  @extend %row;
  justify-content: flex-end; }

.row--center {
  @extend %row;
  justify-content: center; }

.row--verticalCenter {
  @extend %row;
  align-items: center; }


////////////////////////////////////////////////////////////////////////////////
// Other Flex Objects
.dynamicBody {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}


// Spacing

.gap--large {
  gap: var(--space--large)
}

.gap {
  gap: var(--space)
}

.gap--small {
  gap: var(--space--small)
}

.gap--smaller {
  gap: var(--space--smaller)
}