@media print {
    html,
    body,
    .body,
    .page,
    app-jobs,
    .page-content,
    .page-content-main,
    .job-main--wrapper {
        height: auto !important;
        overflow-x: visible !important;
        overflow-y: visible !important;
        overflow: visible !important;
    }
    .u-fill {
        min-height: auto !important;
    }
    .body,
    .overlay,
    .overlay-page {
        position: static !important;
        height: auto !important;
    }
    .step-header {
        position: static !important;
    }
    .dynamic-body,
    .dynamicBody{
        height: auto !important;
        overflow-x: visible !important;
    }
    .mat-expansion-panel-content {
        height: auto !important;
        visibility: visible !important;
    }
    .chooseProposal-customer {
        border: 0 !important;
        margin: 0 !important;
    }

    /* Hide certain elements */
    .consultantBoxToggle,
    .job-main,
    .banner,
    .header,
    .queue,
    .footer {
        display: none !important;
    }
}


// Proposal View

@media print {
    .linkedProposalsWrapper {
        flex-direction: column !important; 
        height: auto !important; 
    }
    .proposalView .proposalOptions-brand-options {
      grid-template-columns: auto auto;
    }
}
