.u-cursor--pointer {
  cursor: pointer;
}
.u-cursor--not-allowed {
  cursor: not-allowed;
}
.u-cursor--help {
  cursor: help;
}

.u-disabled {
  @include opacity(disabled);
  cursor: not-allowed;
  pointer-events: none
}

.u-progress {
  @include opacity(disabled);
  cursor: progress;
}

.u-hoverEffect--color {
  @include hoverEffect(color);
}

.u-hoverEffect {
  @include hoverEffect;
}
