.m-theme--dark {

  --body--background-color: #404044;
  --font-color--base: rgba(255,255,255,.9);

  // --font-color--header: #ffffffa8;
  // --font-color--display: #ffffff;

  --organism--background--default: rgb(83, 82, 84);
  --organism--color--default: var(--font-color--base);

  --navbar--background: #{$background-color--black--1};
  --sidebar--background: #{$background-color--black--0};
  --queue--background: #{$background-color--black--3};
  --queue--color: #FFF;
  --navbar--background: #{$background-color--black--1};
  --navbar--color: var(--font-color--base);

  --input--background-color: rgba(0,0,0,.15);
  --input--color: var(--font-color--base);
  --input--border: solid 1px rgba(0,0,0,.1);

  --box-shadow--rgb--light: 80,80,80;
  --box-shadow--rgb--dark: 0,0,0;

  --neumorphic-box-shadow--dark: rgba(var(--box-shadow--rgb--dark),.2);
  --neumorphic-box-shadow--light: rgba(var(--box-shadow--rgb--light), .5);

  @include setInteractionColor(#f1b06c);


  .banner {
    --organism--background: #{$background-color--black--4};
  }

  .sidebar {
    @include setIconColors(rgba(255,255,255,.7), rgba(255,255,255,.4));
  }

}
