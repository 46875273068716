// Native HTML Inputs

input,
button,
textarea,
select {
  font-size: inherit;
  font-family: inherit;
  @include focusOutline;

  &[disabled="true"] {
    @include opacity(light);
    cursor: not-allowed;
    --input--background-color: transparent; }

}

select,
input[type="date"] {
  cursor: pointer;
}


//------------------------------------------------------------------------------
// TEXT INPUTS

input,
input[type="text"],
input[type="password"],
input[type="search"],
textarea {
  width: 100%;
  min-height: var(--minHitArea);
  height: var(--minHitArea);
  background-color: var(--input--background-color);
  color: var(--input--color);
  padding: var(--space--smaller) var(--space--small);
  box-sizing: border-box;
  border: var(--input--border);
  border-radius: var(--input--border-radius);

  &::placeholder {
  }

  //-------------------------------
  &.is-small {
    --minHitArea: --minHitArea--small;
  }

  &.is-readOnly {
    background-color: transparent;
    border: none;
    pointer-events: none;
  }

}

//-------------------------------
// select / option

select {
  &.is-readOnly {
    padding-right: 0;
    background-color: transparent;
    border: none;
    pointer-events: none;
    background-position: calc(100% + 10rem);
  }
  //-------------------------------
  &.is-small {
    --minHitArea: --minHitArea--small;
  }

}


//-------------------------------
// textarea

textarea {
  resize: none; // by default, we disabled resizing. It is enabled via modifiers
  height: auto; // override default input height above

  &.resize--both       { resize: both; }
  &.resize--horizontal { resize: horizontal; }
  &.resize--vertical   { resize: vertical; }
}


//-------------------------------
// TYPE="search"

// NOTE this should be used rarely.
// We should lean towards using our .search > input + button

@media not all and (min-resolution:.001dpcm) {
  // targets Safari
  input[type="search"] {
    -webkit-appearance: textfield;
    &::-webkit-input-placeholder {
      text-indent: var(--space)
    }
  }

}

// Color

input[type="color"] {
  cursor: pointer;
  padding: 0;
  border: var(--input--border);
  border-radius: var(--input--border-radius);
}

// States

input,
select {
  &.is-readOnly {
      border: solid 1px transparent !important;
      padding: 0;
      height: 100%;
      min-height: 0;
  }

  .field > & {
      transition: all ease .3s;
  }
}

.is-disabled {
  opacity: 0.8;
  cursor: not-allowed;
  --input--background-color: transparent;
}
