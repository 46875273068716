.u-box-shadow--0 {
  @include box-shadow(0); }

.u-box-shadow--1 {
  @include box-shadow(1); }

.u-box-shadow--2 {
  @include box-shadow(2); }

.u-box-shadow--3 {
  @include box-shadow(3); }
