.fut {

  &-body {
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &-column {
    flex-shrink: 0;
    display: flex;
    flex-flow: column;
    width: 20rem;
    overflow: hidden;
    border-right: solid .5rem rgba(0,0,0,.03);
    border-right: solid 0.1rem rgba(0, 0, 0, 0.06);

    &-header {
      background-color: #FFF;
      padding: var(--space--smaller) var(--space--small);
      border-bottom: solid .1rem rgba(0,0,0,.07);
      font-size: 1.1rem;
      min-height: 4.4rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      margin: 0;
    }

    &-body {
      flex-grow: 1;
      overflow-x: hidden;
      overflow-y: auto;
      gap: 1rem;
      display: flex;
      flex-flow: column;
      padding: var(--space--smaller);

      &.cdk-drop-list-dragging {
        background-color: var(--interaction--lightest);
        box-shadow: inset 0 0 0 .2rem var(--interaction--lighter)
      }

    }

  }

  &-job {
    padding: 0;
    overflow: hidden;

    &.card {
      transition: none;
    }

    .draggableArea {
      cursor: grab;
      &:active { cursor: grabbing }
    }
  }
  
}

.fut-column--lost {
  background-color: rgba(0,0,0,.025);
  .fut-job.card { 
    background-color: #efefef;
  border: solid 1px rgba(0,0,0,.1)}
}

.fut-column--sold {
  background-color: #FFF;
  .fut-job.card {
    border: solid 1px rgba(0,0,0,.1)
  }
}

.job-card-reminder {
  padding-right: 0;
}

.cdk-drag-preview {
  @include box-shadow(3)
}

.cdk-drag-placeholder {
  opacity: .4;
}
