.banner {
  --organism--background: #242424;
  --organism--color: rgba(255,255,255,.8);
  @include layer(2);

  .tab:not(.is-active):focus {
    background-color: var(--interaction--lighter);
    color: #FFF;
  }

}

.avi {
  background-color: rgb(41 75 86 / 17%);
  box-shadow: none;
}
