:root {
  // set defaults (should be done in the main variable/cprop file)
  --tab-button--padding-topBottom: var(--button--padding-topBottom, 0);
  --tab-button--padding-leftRight: var(--button--padding-leftRight, var(--space));
  --tab--height: var(--minHitArea);
}


.tabs {
  @include flex;
  align-items: stretch;
  flex-shrink: 0;
  .tab {
    @include flex;
    @include minHit;
    @include transition;
    @include truncate;
    align-items: center;
    min-height: var(--tab--height);
    padding: var(--tab-button--padding-topBottom) var(--tab-button--padding-leftRight);
    font-size: var(--tab-button--font-size, var(--button--font-size, var(--font-size--base)));
    border-radius: var(--tab-button--border-radius, var(--button--border-radius, var(--input--border-radius)));
    margin: 0 var(--tab--gap, var(--space--smaller)) 0 0;
    text-align: center;
    cursor: pointer;
  }
}

.tab {
  &:hover,
  &:focus {
    outline: none;
  }
  &:not(.is-active):hover,
  &:not(.is-active):focus {
    background-color: var(--interaction--lightest);
    color: var(--interaction--darker); }

  &.is-active {
    background-color: var(--tab-button--active, var(--active, var(--interaction)));
    color: var(--tab-button--active--contrast, var(--active--contrast, var(--interaction--contrast))); }

}

.tab.icon {
  height: 100%;
}

//------------------------------------------------------------------------------

.tabs--secondary {
  .tab {
    border-bottom: solid .3rem transparent;
    border-radius: 0;
    background-color: transparent;

    &:not(.is-active):hover,
    &:not(.is-active):focus {
      background-color: transparent;
      color: var(--interaction--dark);
      border-bottom-color: var(--interaction--lightest); }

    &.is-active {
      --tab-button--border-radius: 0;
      color: var(--active, var(--interaction));
      border-bottom-color: var(--active, var(--interaction)); }

  }
}

//------------------------------------------------------------------------------

.tabs--dotted {
  .tab {
    background-color: transparent;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-right: var(--space);
    padding: 0;
    font-weight: $font-weight--normal;

    &:after {
      @include transition();
      content: '';
      height: .6rem;
      width: .6rem;
      border-radius: 1000rem;
      transform: scale(0);
      opacity: 0;
    }

    &:not(.is-active):hover,
    &:not(.is-active):focus {
      background-color: transparent;
      color: var(--interaction--dark);
      border-bottom-color: var(--interaction--lightest); }

    &.is-active {
      color: var(--active, var(--interaction));
      font-weight: bold;
      &:after {
        background-color: var(--active);
        opacity: 1;
        transform: scale(1) translateY(var(--space--smallest));
      }
    }
  }
}


//------------------------------------------------------------------------------

.tabs--page {
  --tab-button--border-radius: var(--input--border-radius);
  .tab {
    border: $border--black--default;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;

    &:not(.is-active) {
      border-top-color: transparent;
      border-right-color: transparent;
      border-left-color: transparent; }

    &:not(.is-active):hover,
    &:not(.is-active):focus {
      color:  var(--interaction--darker); }

    &.is-active {
      color: var(--active, var(--interaction));
      border-bottom: $border--black--default;
      background-color: transparent;
      border-bottom-color: transparent; }

  }
}


//------------------------------------------------------------------------------

.tabs--light {
  .tab {
    // color: #FFF;

    &:not(.is-active):hover,
    &:not(.is-active):focus {
      background-color: rgba(255,255,255,.1);
      color: #FFF; }

    &.is-active {
      background-color: #FFF;
      color: var(--active, var(--interaction)); }

  }
}


//------------------------------------------------------------------------------
//----- Util Combos

.tabs {
  &.u-overflow-x--auto {
    @extend .u-scrollbar--hide;
    .tab {
      min-width: max-content;
    }
  }
  &.row--wrap {
    flex-wrap: wrap;
  }
  &.u-truncate {
    .tab > * {
      @include truncate;
    }
  }
  &.u-truncate--none {
    .tab {
      white-space: normal;
      word-wrap: initial;
      word-break: initial;
      overflow-wrap: initial;
      min-width: min-content;
    }
  }
  &.is-small {
    --tab-button--font-size: var(--font-size--small);
    --tab-button--padding-topBottom: var(--space--small);
    --tab-button--padding-leftRight: var(--space);
  }
}

//------------------------------------------------------------------------------
//----- input based tabs
// Makes Radio buttons look like tabs--secondary

.tabs--secondary input[type="radio"].tab {

  & + label:before,
  & + label:after {
    content: unset;
    display: none;
  }

  & + label {
    @include flex;
    @include minHit;
    @include transition;
    @include truncate;
    align-items: center;
    min-height: var(--tab--height);
    padding: var(--tab-button--padding-topBottom) var(--tab-button--padding-leftRight);
    font-size: var(--tab-button--font-size, var(--button--font-size, var(--font-size--base)));
    margin: 0 var(--tab--gap, var(--space--smaller)) 0 0;
    text-align: center;
    cursor: pointer;
    border-bottom: solid .3rem transparent;
    border-radius: 0;
    background-color: transparent;
  }

  &:not(:checked):hover + label,
  &:not(:checked):focus + label {
    background-color: transparent;
    color: var(--interaction--dark);
    border-bottom-color: var(--interaction--lightest); }

  &:checked + label {
    --tab-button--border-radius: 0;
    color: var(--active, var(--interaction));
    border-bottom-color: var(--active, var(--interaction)); }


}
