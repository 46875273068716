@use 'sass:math';

//------------------------------------------------------------------------------

@mixin alertColor($alert--type: info) {
  @include checkMixinForValidArguments($alert--type, info success warning danger);

  @if $alert--type == success {
    color: var(--alert--success);
    --font-color--header: var(--alert--success);
    --font-color--display: var(--alert--success);
  } @else if $alert--type == warning {
    color: var(--alert--warning);
    --font-color--header: var(--alert--warning);
    --font-color--display: var(--alert--warning);
  }  @else if $alert--type == danger {
    color: var(--alert--danger);
    --font-color--header: var(--alert--danger);
    --font-color--display: var(--alert--danger);
  } @else {
    color: var(--alert--info);
    --font-color--header: var(--alert--info);
    --font-color--display: var(--alert--info);
  }

}

@mixin alertColors($alert--type: info) {
  @include checkMixinForValidArguments($alert--type, info success warning danger);

  @if $alert--type == success {
    background-color: var(--alert--success);
    color: var(--alert--success--contrast);
    --font-color--header: var(--alert--success--contrast);
    --font-color--display: var(--alert--success--contrast);
  } @else if $alert--type == warning {
    background-color: var(--alert--warning);
    color: var(--alert--warning--contrast);
    --font-color--header: var(--alert--warning--contrast);
    --font-color--display: var(--alert--warning--contrast);
  }  @else if $alert--type == danger {
    background-color: var(--alert--danger);
    color: var(--alert--danger--contrast);
    --font-color--header: var(--alert--danger--contrast);
    --font-color--display: var(--alert--danger--contrast);
  } @else {
    background-color: var(--alert--info);
    color: var(--alert--info--contrast);
    --font-color--header: var(--alert--info--contrast);
    --font-color--display: var(--alert--info--contrast);
  }

}

//------------------------------------------------------------------------------

@mixin animation($css-animationName, $speed: $animation--speedDefault, $direction: forwards, $delay: null) {

    @include checkMixinForValidArguments($css-animationName, boxShadow--wripple fadeIn fadeOut popIn slideIn--up slideIn--right slideIn--down slideIn--left growIn--height);

    @if (type-of($speed) == string ) {
      @include checkMixinForValidArguments($speed, fast slow);
    }

    @if $speed == fast {
      $speed: $animation--speedDefault / 2
    } @else if $speed == slow {
      $speed: $animation--speedDefault * 2
    }

    @include browserPrefix((
        animation: $css-animationName $speed $direction
    ));

}

//------------------------------------------------------------------------------


@mixin box-shadow($level--1to3: 1, $shadowColor: $box-shadow--color) {

  @if $level--1to3 == 0 {
    box-shadow: $box-shadow--0;
  }
  @else if $level--1to3 == 1 {
    box-shadow: $box-shadow--1;
  }
  @else if $level--1to3 == 2 {
    box-shadow: $box-shadow--2;
  }
  @else if $level--1to3 == 3 {
    box-shadow: $box-shadow--3;
   }
  // NONE - removes dropShadow
  @else if $level--1to3 == none {
    box-shadow: none;
  }
}

//------------------------------------------------------------------------------

$browserPrefixes: webkit ms moz o; // declare full list of prefixes.
@mixin browserPrefix($declarations, $prefixesToSet:$browserPrefixes) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixesToSet {
      #{'-' + $prefix + '-' + $property}: $value;
    }
    #{$property}: $value;
  }
}

//------------------------------------------------------------------------------

@mixin checkMixinForValidArguments($input, $expected) {
  @if (index($expected, $input) == null) {
    @warn "'#{$input}' is not a valid argument... We expected one of the following '#{$expected}' ";
  }
}


//------------------------------------------------------------------------------

@mixin flex($flexDirection-property: row, $flexWrap-property: nowrap) {
  display: flex;
  flex-flow: $flexDirection-property $flexWrap-property;
}

@mixin focusOutline() {
  &:focus {
    outline: none;
    box-shadow: 0 0 .2rem .2rem var(--focus, var(--interaction--light));
  }
}

//------------------------------------------------------------------------------

@mixin fontSize($size) {
  @include checkMixinForValidArguments($size, large default small smallest);
  @if $size == 'large' {
    font-size: var(--font-size--large);
  } @else if $size == 'default' {
    font-size: var(--font-size--base);
  } @else if $size == 'small' {
    font-size: var(--font-size--small);
  } @else if $size == 'smallest' {
    font-size: var(--font-size--smallest);
  }
}

//------------------------------------------------------------------------------

@mixin genericSize($choose--width-height-both, $generic-size) {
    @if ($choose--width-height-both == width) or ($choose--width-height-both == height) or ($choose--width-height-both == both)   {
          @if map-has-key($config--ui--sizes--generic,$generic-size) {
            @if ($choose--width-height-both == width) {
              width: map-get($config--ui--sizes--generic, $generic-size); }

            @if ($choose--width-height-both == height) {
              height: map-get($config--ui--sizes--generic, $generic-size); }

            @if ($choose--width-height-both == both) {
              height: map-get($config--ui--sizes--generic, $generic-size);
              width: map-get($config--ui--sizes--generic, $generic-size); }
          }

          @else {
            @warn "No value could be retrieved for `#{$generic-size}`. "
                + "Argument passed into genericSize() must be defined in `$config--ui--sizes--generic` map.";
          }

    }
    @else {
        @error "You tried to use #{$choose--width-height-both}... Argument passed into genericSize() must be 'width', 'height', or 'both' ";
    }

}

//------------------------------------------------------------------------------
// Gradients

@mixin gradient($color--1, $color--2) {
    background: $color--1; /* Old browsers */
    background: -moz-linear-gradient(-45deg, $color--1 0%, $color--2 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, $color--1 0%,$color--2 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, $color--1 0%,$color--2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color--1}', endColorstr='#{$color--2}',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

//------------------------------------------------------------------------------

@mixin brandGradient($choose--warm-cool-mix:default , $direction: ltr) {
  @include checkMixinForValidArguments($choose--warm-cool-mix, warm cool mix default);

  @if $direction == ttb {
    --gradient--directionDegree: 0deg;
  }
  @else if $direction == btt {
    --gradient--directionDegree: 180deg;
  }
  @else if $direction == ltr {
    --gradient--directionDegree: 45deg;
  }
  @else if $direction == rtl {
    --gradient--directionDegree: -45deg;
  }


    @if $choose--warm-cool-mix == warm {
    --gradient--background-color--1: var(--gradient--warm--1);
    --gradient--background-color--2: var(--gradient--warm--2);

    } @else if $choose--warm-cool-mix == cool {
    --gradient--background-color--1: var(--gradient--cool--1);
    --gradient--background-color--2: var(--gradient--cool--2);

    } @else if $choose--warm-cool-mix == mix {
    --gradient--background-color--1: var(--gradient--mix--1);
    --gradient--background-color--2: var(--gradient--mix--2);

    } @else if $choose--warm-cool-mix == default {
    --gradient--background-color--1: var(--gradient--default--1);
    --gradient--background-color--2: var(--gradient--default--2);
    }

   @extend %gradient;


}

//------------------------------------------------------------------------------

@mixin header($h--1-through-6) {
  @include checkMixinForValidArguments($h--1-through-6, 1 2 3 4 5 6);

  @if $h--1-through-6 == 1 {
    font-size: calc(var(--font-size--base) * 2.2);
    font-weight: $font-weight--light;
    letter-spacing: -.1px;
    &.is-responsive {
      font-size: clamp( var(--font-size--base) * 1.6, 5vw, var(--font-size--base) * 2.3 )
    }
  } @else if $h--1-through-6 == 2 {
    font-size: calc(var(--font-size--base) * 1.8);
    font-weight: $font-weight--bold;
    &.is-responsive {
      font-size: clamp( var(--font-size--base) * 1.4, 4vw, var(--font-size--base) * 1.8 )
    }
  } @else if $h--1-through-6 == 3 {
    font-size: calc( var(--font-size--base) * 1.5);
    font-weight: $font-weight--normal;
    &.is-responsive {
      font-size: clamp( var(--font-size--base) + .3rem, 3vw, var(--font-size--base) * 1.5 )
    }
  } @else if $h--1-through-6 == 4 {
    font-size: calc( var(--font-size--base) + .4rem);
    font-weight: $font-weight--bold;
    &.is-responsive {
      font-size: clamp( var(--font-size--base) + .2rem, 3vw, var(--font-size--base) + .4rem )
    }
  } @else if $h--1-through-6 == 5 {
    font-size: calc( var(--font-size--base)  - .2rem);
    font-weight: $font-weight--bold;
    text-transform: uppercase;
  } @else if $h--1-through-6 == 6 {
    $size: calc( var(--font-size--base) - .2rem);
    font-size: $size;
    font-weight: $font-weight--normal;
    text-transform: uppercase;
  }
}


@mixin hoverEffect($choosenone-plain-opacity-default: gray) {
  @include checkMixinForValidArguments($choosenone-plain-opacity-default, none plain opacity gray color white);

    @include transition($easing: ease-out);
    cursor: pointer;

    @if $choosenone-plain-opacity-default == none {
        cursor: default;
        &:hover,
        &:focus {
          background-color: initial;
        }
    }
    @else if $choosenone-plain-opacity-default == plain {
        &:hover,
        &:focus {
          background-color: initial;
        }
    }
    @else if $choosenone-plain-opacity-default == opacity {
        &:not(:hover),
        &:not(:focus) {
          @include opacity(full); }
        &:hover,
        &:focus {
          @include opacity(lighter);
        }
    }
    @else if $choosenone-plain-opacity-default == white {
      &:hover,
      &:focus {
        background-color: $background-color--white--hover;
      }
    }
    @else {
        &:hover,
        &:focus {
          background-color: $background-color--gray--hover;
          @if $choosenone-plain-opacity-default == color {
            background-color: $background-color--color--hover;
          }
        }
    }
}


//------------------------------------------------------------------------------

@mixin layer($z: 0) {

   @include checkMixinForValidArguments($z, 0 1 2 3 4 5 high highest);

    @if $z == 0 {
        $z: $layer--0;
    } @else if $z == 1 {
        $z: $layer--1;
    } @else if $z == 2 { // queue , navpanel
        $z: $layer--2;
    } @else if $z == 3 { // clickaway
        $z: $layer--3;
    } @else if $z == 4 { // Filter Panels , drawers
        $z: $layer--4;
    } @else if $z == 5 { // Dropdowns - Demographics
        $z: $layer--5;
    } @else if $z == 'high' { // Models, add/edit
        $z: $layer--high;
    } @else if $z == 'highest' {  // Timeout Model
        $z: $layer--highest;
    }
    z-index: $z;
}


//------------------------------------------------------------------------------

@mixin minHit($extent: both) {
  @include checkMixinForValidArguments($extent, both width height exact);
  @if $extent == both {
    min-width: var(--minHitArea);
    min-height: var(--minHitArea);
  } @else if $extent == width {
    min-width: var(--minHitArea);
  } @else if $extent == height {
    min-height: var(--minHitArea);
  } @else if $extent == exact {
    min-width: var(--minHitArea);
    min-height: var(--minHitArea);
    width: var(--minHitArea);
    height: var(--minHitArea);
  }
}

//------------------------------------------------------------------------------

@mixin opacity($o) {

    @include checkMixinForValidArguments($o, light lighter disabled lightest full);

    @if $o == 'light' {
        opacity: $opacity--light;
    } @else if $o == 'lighter' {
        opacity: $opacity--lighter;
    } @else if $o == 'lightest' or $o == 'disabled'  {
        opacity: $opacity--lightest;
    } @else if $o == 'full'  {
        opacity: 1;
    } @else {
        opacity: $o;
    }
}

//------------------------------------------------------------------------------

@mixin position($position, $top: initial, $right: initial, $bottom: initial, $left: initial) {
  @if ($position == 'sticky') {
    position: -webkit-sticky;
    position: sticky;
    @include layer(1); // we need to define the z index by default - so we choose a base (low) one
    @if ($top != initial) {
      top: $top;
    } @else {
      top: 0;
    }
  } @else {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }
}

//------------------------------------------------------------------------------

@mixin preserve {
    &:not(:required) {
        min-width: 0;
        overflow: visible;
        text-overflow: inherit;
        @include browserPrefix((
            flex: 0 0 auto,
        ));
    }
}

//------------------------------------------------------------------------------

@mixin targetScreenSizeRange($screen--mobile-sm-md-lg-xl-xxl) {

  @include checkMixinForValidArguments($screen--mobile-sm-md-lg-xl-xxl, mobile sm md lg xl xxl);

  @if $screen--mobile-sm-md-lg-xl-xxl == mobile {
    @media (min-width: 0) and (max-width: $screen--mobile--portrait) {
      @content;
    }
  }

// TODO Finish the scaling up n down
  // @if $screen--mobile-sm-md-lg-xl-xxl == mobile_up {
  //   @media (min-width: $screen--mobile--portrait) {
  //     @content;
  //   }
  // }

  @if $screen--mobile-sm-md-lg-xl-xxl == sm {
    @media (min-width: $screen--mobile--portrait) and (max-width: $screen--mobile) {
      @content;
    }
  }
  @if $screen--mobile-sm-md-lg-xl-xxl == md {
    @media (min-width: $screen--mobile) and (max-width: $screen--medium) {
      @content;
    }
  }
  @if $screen--mobile-sm-md-lg-xl-xxl == lg {
    @media (min-width: $screen--medium) and (max-width: $screen--large) {
      @content;
    }
  }
  @if $screen--mobile-sm-md-lg-xl-xxl == xl {
    @media (min-width: $screen--large) and (max-width: $screen--larger) {
      @content;
    }
  }
  @if $screen--mobile-sm-md-lg-xl-xxl == xxl {
    @media (min-width: $screen--larger) and (max-width: 100000px) {
      @content;
    }
  }
}

@mixin titles($t--1-through-6) {
  @include checkMixinForValidArguments($t--1-through-6, 1 2 3 4 5 6);

  @if $t--1-through-6 == 1 {
    .title {
      @extend %h1; }
    .subtitle {
      line-height: 1.4;
      margin-top: var(--space--smaller);
    }
  } @else if $t--1-through-6 == 2 {
    .title {
      @extend %h2; }
    .subtitle {
      @include fontSize(small);
      line-height: 1.3;
      margin-top: var(--space--smaller);
    }
  } @else if $t--1-through-6 == 3 {
    .title {
      @extend %h3; }
    .subtitle {
      @include fontSize(small); }
  } @else if $t--1-through-6 == 4 {
    .title {
      @extend %h4; }
    .subtitle {
      @include fontSize(small); }
  } @else if $t--1-through-6 == 5 {
    .title {
      @extend %h5; }
    .subtitle {
      @include fontSize(smallest); }
  } @else if $t--1-through-6 == 6 {
    .title {
      @extend %h6; }
    .subtitle {
      @include fontSize(smallest); }
  }
}

//------------------------------------------------------------------------------

@mixin transition($speed: $animation--speedDefault, $easing: ease-in-out) {
  @if $speed == fast {
    $speed: math.div($animation--speedDefault, 2)
  } @else if $speed == slow {
    $speed: $animation--speedDefault * 2
  }
  @include browserPrefix((
    transition: all $speed $easing
  ));
}

//------------------------------------------------------------------------------

@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//------------------------------------------------------------------------------

@mixin wordWrap($extent:"normal") {

  @include checkMixinForValidArguments($extent, all normal none);

  @if $extent == "all" {
    white-space: normal;
    word-break: break-all;
  } @else if $extent == "normal"  {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
  } @else if $extent == "none" {
    @include truncate;
  }
}
