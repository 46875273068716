.modal--tall {
  width: 45rem;
  max-width: 100vw;
  min-height: 60rem;
  height: 80rem;
  max-height: 90vh;
}

.modal--default {
  width: clamp(30rem,50rem,70rem);
  max-width: calc(100vw - var(--space--small) * 2);
  max-height: 96vh;
  min-height: 0;
}

.modal--content {
  width: clamp(30rem,100%,95rem);
  max-width: calc(100vw - var(--space--small) * 2);
  max-height: 96vh;
  min-height: 0;
}

.modal--full {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: 0;
}

.offsetModalPadding-right {
  transform: translateX(1rem);
}

.offsetModalPadding-left {
  transform: translateX(-1rem);
}