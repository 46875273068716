.footer {
  @include flex;
  min-height: var(--footer--height);
  background-color: var(--footer--background-color, transparent);
  color: var(--footer--color, var(--font-color--base));
  padding: var(--padding--tb) var(--padding--lr);
  border-top: $border--black--organism;
  justify-content: flex-end;
  flex-shrink: 0;
}

:root {
  // Unset, falls to defaults
  // --footer--background-color: #{$background-color--black--5};
  // --footer--color: var(--font-color--base);
}
