//------------------------------------------------------------------------------
// MAIN THEME
:root {
  --body--background-color: #f4f4f5; // #EFF1F4; //#f8f8f8;
  --font-color--base: hsl(0, 0%, 20%);
  --font-color--contrast: #FFFFFF; // base over dark bg
  --organism--background--default: #FFF;
  --overlay--background-color: #{$background-color--black--1};

  --queue--background: var(--body--background-color);
  --card--background: #fafafa;

}

//------------------------------------------------------------------------------
// Typography
:root {
  --font-family--default: #{$font-family--default};
  --font-size--base: 1.6rem;
  --font-size--smallest: calc( var(--font-size--base) * .75);
  --font-size--small: calc( var(--font-size--base) * .75 + .2rem);
  --font-size--large: calc( var(--font-size--base) * 1.25 );
  --line-height--base: 1.5; // needs to be unitless
}


//------------------------------------------------------------------------------
// Brand / Theme Primary Colors
:root {
  --brand--1: #{map-get($config--ui--brandColors, brand--1)};
  --brand--1--contrast: #{map-get($config--ui--brandColors, brand--1--contrast)};

  --brand--2: #{map-get($config--ui--brandColors, brand--2)};
  --brand--2--contrast: #{map-get($config--ui--brandColors, brand--2--contrast)};

  --brand--3: #{map-get($config--ui--brandColors, brand--3)};
  --brand--3--contrast: #{map-get($config--ui--brandColors, brand--3--contrast)};

  --brand--4: #{map-get($config--ui--brandColors, brand--4)};
  --brand--4--contrast: #{map-get($config--ui--brandColors, brand--4--contrast)};

  --brand--5: #{map-get($config--ui--brandColors, brand--5)};
  --brand--5--contrast: #{map-get($config--ui--brandColors, brand--5--contrast)};

  --brand--6: #{map-get($config--ui--brandColors, brand--6)};
  --brand--6--contrast: #{map-get($config--ui--brandColors, brand--6--contrast)};
}


//------------------------------------------------------------------------------
// Interaction Color
@mixin setInteractionColor($newColor, $newColor--contrast: #fff) {
  --interaction: #{$newColor};
  --interaction--contrast: #{$newColor--contrast}; // color overtop
  --interaction--dark: #{darken($newColor, 10)};
  --interaction--darker: #{darken($newColor, 15)};
  --interaction--darkest: #{darken($newColor, 25)};
  --interaction--light: #{rgba($newColor, .7)};
  --interaction--lighter: #{rgba($newColor, .25)};
  --interaction--lightest: #{rgba($newColor, .1)};
}

:root {
  --interaction--fullHue: #c6f710;
  @include setInteractionColor(#4a5d6a, #FFF); 
  --active: #687279;
  --active--contrast: #fff;
}


//------------------------------------------------------------------------------
// Inputs
:root {
  --input--background-color: rgba(255,255,255,.9);
  --input--color: var(--font-color--base);
  --input--border: solid 1px rgba(0,0,0,.1);
  --input--border-radius: .5rem;
}


//------------------------------------------------------------------------------
// Button
:root {
  // --button--border-radius: UNSET, DEFAULTS --input--border-radius;
  // --button--padding-leftRight: UNSET, DEFAULTS --space;
  // --button--padding-topBottom: UNSET, DEFAULTS 0;
}

:root {
  // --tab--height: UNSET, DEFAULTS  --minHitArea;
  // --tab--gap: UNSET, DEFAULTS --space--smallest;
  // --tab-button--font-size: UNSET, DEFAULTS --button--font-size , --font-size--base;
  // --tab-button--active: UNSET, DEFAULTS --active, --interaction;
  // --tab-button--active--contrast: UNSET, DEFAULTS --active, --interaction;
  // --tab-button--border-radius: UNSET, DEFAULTS --button--border-radius, --input--border-radius;
  // --tab-button--padding-leftRight: UNSET, DEFAULTS --button--padding-leftRight, --space;
  // --tab-button--padding-topBottom: UNSET, DEFAULTS --button--padding-topBottom, 0;
}

//------------------------------------------------------------------------------
// Icons

@mixin setIconColors($primary, $secondary:$primary) {
  --icon-path--primary: #{$primary};
  --icon-path--secondary: #{$secondary};
}

:root {
  @include setIconColors( rgba(0,0,0,.9), rgba(0,0,0,.6))
}


//------------------------------------------------------------------------------
// Borders
:root {
  --border--color--rgb--light: 255,255,255;
  --border--color--rgb--dark: 0,0,0;

  --border-radius: .5rem;
  --border-radius--large: 1rem;
}


//------------------------------------------------------------------------------
// Box Shadow
:root {
  --box-shadow--rgb--dark: 125, 130, 150;
  --box-shadow--rgb--light: 255, 255, 255;
}


//------------------------------------------------------------------------------
// Gradient Backgrounds
:root {
  --gradient--directionDegree: 45deg;

  --gradient--default--1: #{map-get($config--ui--gradientColors, default--1)};
  --gradient--default--2: #{map-get($config--ui--gradientColors, default--2)};

  --gradient--warm--1: #{map-get($config--ui--gradientColors, warm--1)};
  --gradient--warm--2: #{map-get($config--ui--gradientColors, warm--2)};

  --gradient--cool--1: #{map-get($config--ui--gradientColors, cool--1)};
  --gradient--cool--2: #{map-get($config--ui--gradientColors, cool--2)};

  --gradient--mix--1: #{map-get($config--ui--gradientColors, mix--1)};
  --gradient--mix--2: #{map-get($config--ui--gradientColors, mix--2)};
}


//------------------------------------------------------------------------------
// Alert Colors
:root {
  --alert--info: #{map-get($config--ui--alertColors, info)};
  --alert--info--contrast: #{map-get($config--ui--alertColors, info--contrast)};

  --alert--warning: #{map-get($config--ui--alertColors, warning)};
  --alert--warning--contrast: #{map-get($config--ui--alertColors, warning--contrast)};

  --alert--danger: #{map-get($config--ui--alertColors, danger)};
  --alert--danger--contrast: #{map-get($config--ui--alertColors, danger--contrast)};

  --alert--success: #{map-get($config--ui--alertColors, success)};
  --alert--success--contrast: #{map-get($config--ui--alertColors, success--contrast)};
}


//------------------------------------------------------------------------------
// Default spacing
:root {
  --space--largest: #{map-get($config--ui--spacing, largest)};
  --space--larger: #{map-get($config--ui--spacing, larger)};
  --space--large: #{map-get($config--ui--spacing, large)};
  --space: #{map-get($config--ui--spacing, default)};
  --space--small: #{map-get($config--ui--spacing, small)};
  --space--smaller: #{map-get($config--ui--spacing, smaller)};
  --space--smallest: #{map-get($config--ui--spacing, smallest)};

  --padding--lr: var(--space);
  --padding--tb: var(--space--small);
}


//------------------------------------------------------------------------------
// UI COMPONENT SPECS
:root {

  // Molecules
  --header--height: auto;
  --footer--height: auto;

  // Organisms
  --card--width: 32rem;
  --navbar--height: 7rem;
  --panel--width: 32rem;
  --queue--width: 32rem;
  --sidebar--width: 8rem;
  --drillin--width: 100%;

}


//------------------------------------------------------------------------------
// Hit Area
:root {
  --minHitArea: 4.4rem;
  --minHitArea--small: 3.2rem;
}


//------------------------------------------------------------------------------
// Checkbox
:root {
  --checkbox--size: 1.8rem;
  --checkbox-label--padding--tb: var(--space--small);
  --checkbox-label--padding--lr: var(--space--small);
}


//------------------------------------------------------------------------------
// background color utilities
:root {
  --config--background-color--dark--h: 250;
  --config--background-color--dark--s: 40%;
  --config--background-color--dark--l: 10%;
  --config--background-color--dark--a: 1;
}


//------------------------------------------------------------------------------
// Page Container
:root {
  --page-container--max-width: 1800px;
  --page-container--padding--tb: var(--space);
  --page-container--padding--lr: var(--space);
}
