@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

@mixin setInteractionColor($newColor, $newColor--contrast: #fff) {
  --interaction: #{$newColor};
  --interaction--contrast: #{$newColor--contrast}; // color overtop
  --interaction--dark: #{darken($newColor, 10)};
  --interaction--darker: #{darken($newColor, 15)};
  --interaction--darkest: #{darken($newColor, 25)};
  --interaction--light: #{rgba($newColor, .7)};
  --interaction--lighter: #{rgba($newColor, .25)};
  --interaction--lightest: #{rgba($newColor, .1)};
}

.proposalView {
  $interactionBaseColor : #4a5d6a;
  @include setInteractionColor($interactionBaseColor, #FFF);
  --btn-group-btn--font-color: $interactionBaseColor
}

.proposalView {

  .page--chooseProposal {
  &.loading {
    background-color: #FFF;
    & > * { opacity: .2 }
  }
}

.step-header-logo {
  max-height: 10rem;
  width: auto;
}

.group--sign {
  flex-direction: column-reverse;
  .label {
    margin: var(--space--smallest) 0 0 0;
  }
  input.field {
    font-family: 'Dancing Script', cursive;
    border: none;
    border-bottom: solid 2px rgba(0,0,0,.5);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: var(--space--smallest);
    &::placeholder {
      font-family: var(--font-family--default);
    }
  }
}

.u-signature {
  font-family: 'Dancing Script', cursive;
  font-size: 1.8rem;
}

.card {
  --active: var(--branding--secondary);
  position: relative;
  overflow: hidden;

  &:not(.is-active):not(.is-incompatible):not(.is-placeholder):not(.is-hiddenForCustomer):hover {
    cursor: pointer;
    // transform: scale(1.01);
    box-shadow: 0 0 0.2rem .1rem var(--active), 0 0 1.5rem -1rem var(--active);
  }

  .incompatibleAlert { display: none; }

  &:after {
    transition: all ease-in-out .2s;
    content: "Selected";
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    background-color: transparent;
    transform: translateX(100%);
    background-color: var(--branding--secondary);
    color: var(--branding--secondaryContrast);
    padding: var(--space--smallest) var(--space--small);
    border-bottom-left-radius: var(--border-radius);
    font-size: 1.2rem;
  }


  &.is-active {
    // --active: var(--branding--secondary);
    box-shadow: 0 0 .2rem .3rem var(--active), 0 0 1.5rem -.3rem var(--active);

    &:after {
      transform: translateX(0);
      opacity: 1 }
    }

    &.is-active.is-incompatible {
      --active: var(--alert--danger);
      opacity: 1;
      cursor: pointer;
    }

    &.is-incompatible {
      opacity: .4;
      box-shadow: 0;

      &:not(.is-active) {
        pointer-events: none;
      }

      .incompatibleAlert { display: flex; }

    }

}

.chooseProposal-enhancements {
  .card.is-active {
    &:after {
      // content: "Chosen";
      width: 6.5rem;
      font-size: var(--font-size--small);
      padding-top: .3rem;
      padding-bottom: .6rem;
      text-align: center;
    }
  }
  .card.is-active:hover {
    cursor: pointer;
    box-shadow: 0 0 .3rem 0 rgba(0,0,0,.2);
    &:after { content: "Remove" }
  }

  .card-image {
    width: 100%;
    height: 100%;
  }
}

.proposal-intro,
.step-header {
  background: var(--branding--primary);
  color: var(--branding--primaryContrast);
}

.step-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.consultantBoxToggle {
  margin-left: var(--space--smaller);
  &.is-open {
    transform: translateY(8rem);
    margin-left: 0;
    // position: absolute;
    // right: var(--padding--variable);
  }
}

.consultantBox {
  transition: all ease-in-out .2s;
  padding: var(--space--small) calc(var(--padding--variable) + 4.4rem) var(--space--small) var(--proposalView--sidePadding);
  flex-shrink: 0;
  &.is-closed {
    height: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0;
  }
}

.step-header-contactTitles.is-bumpRight {
  transform: translateX(4.4rem);
}

// .proposalOptions-brand-options {
//   grid-row-gap: var(--space);
//   grid-column-gap: var(--space);

//   .proposalOptions-brand-option {
//     height: auto;
//     overflow: hidden;

//     &.is-placeholder {
//       height: 100%;
//       opacity: .3;
//       pointer-events: none;
//     }

//     &.is-hiddenForCustomer {
//       background-color: rgba(255,255,255,.25);
//       box-shadow: 0 0 0 .1rem rgba(0,0,0,0.02);
//       .internalPlaceholder-header {
//         opacity: .15;
//       }
//     }
//   }

// }

// .proposalOptions-brand-options {
//   gap: var(--space);
//   display: flex;
//   flex-wrap: wrap;
//   .proposalOptions-brand-option {
//     flex-basis: 38rem;
//     flex-grow: 1;
//   }
// }

.comparisonGridFilterSeer {
  max-width: 8rem;
}

.section--additions ol {
  padding-left: 1.8rem;
  margin-left: .7rem;
}

@media screen and (max-width: 1079px) {

  .proposalOptions-brand {
    padding: var(--space--large) 0 var(--space) 0;
  }

  // .proposalOptions-brand-options {
  //   // Stack as columm on smaller devices. This is an arbitrary number
  //   display: flex;
  //   flex-flow: column;
  //   grid-row-gap: 0;
  //   grid-column-gap: 0;

  //   .proposalOptions-brand-option {
  //     margin-bottom: var(--space--large)
  //   }

  //   .proposalOptions-brand-option.is-placeholder {
  //     display: none;
  //     // hide placeholders when column stacked
  //   }
  // }

  }

@media (min-width: 750px) {
  .section--payment {
    min-height: 45rem
  }
}

@media (max-width: 750px) {

  .step-header-logo {
    max-width: 30vw;
    order: -1;
    margin-right: var(--space--smaller)
  }
  
  .step-header-contactTitles {
    .title,
    .subtitle { font-size: 1.4rem;}
  }

}

@media (max-width: 500px) {

  .step-header-contactTitles {
    &-call { display: none; }
    .title,
    .subtitle { font-size: 1.2rem;}
  }

  .step-header-logoWrapper,
  .step-header-logoWrapper > .step-header-logo { margin-bottom: 0; }
}

@media (max-width: 350px) {
  .step-header-logo {
    width: 10rem;
    max-width: 10rem;
  }
}


}