////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// SPACING UTILITIES

////////////////////////////////////////////////////////////////////////////////
:root {
  --padding--variable: var(--space);

  @media (min-width: $screen--medium) {
    --padding--variable: var(--space) }

  @media (min-width: $screen--large) {
    --padding--variable: var(--space--large) }

  @media (min-width: $screen--larger) {
    --padding--variable: var(--space--larger) }

}

.padding--responsive {
  padding: var(--padding--variable); }

.margin--auto {
  margin-right: auto;
  margin-left: auto; }

.margin-left--auto {
  margin-left: auto; }

.margin-right--auto {
  margin-right: auto; }


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

$elementSpacingTypes: (
    margin: "margin",
    padding: "padding",
);

$elementSides: (
    top: "top",
    right: "right",
    bottom: "bottom",
    left: "left",
    leftRight: "leftRight",
    topBottom: "topBottom",
    inline: "inline"
);

$elementSizes: (
 // note - this order will effect inheritence
 // ie - the lower the item, it will overwrite those above it
    default: var(--space),
    smallest: var(--space--smallest),
    smaller: var(--space--smaller),
    small: var(--space--small),
    large: var(--space--large),
    larger: var(--space--larger),
    largest: var(--space--largest),
    none: 0
);

// generates classes to be used
// NOTE: These are not all the classes generated, just examples
  // FOR EXAMPLE :
  // margin , margin-top , margin-top--tight, margin-top--large,  margin-top--larger
  // margin-bottom , margin-bottom--tight, margin-bottom--large,  margin-bottom--larger
  //
  // padding , padding-top , padding-top--tight, padding-top--large,  padding-top--larger,
  // padding-right , padding-right--tight, padding-right--large,  padding-right--larger
  //

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// CREATION LOOP
@each $name, $type in $elementSpacingTypes {
  @each $sizeDefinition, $sizeValue in $elementSizes {

    @if( map-get($config--cssFeatures, $name) == true ) {
      @if( $sizeDefinition == none ) {
        // For "none", we double up selector to increase specificity
          @if( $sizeDefinition == default ){
            .#{$name}.#{$name} {
              #{$type}: $sizeValue;
            }
        } @else {
          .#{$name}--#{$sizeDefinition}.#{$name}--#{$sizeDefinition} {
            #{$type}: $sizeValue;
          }
        }
        @each $side, $sides in $elementSides {
            @if( $sides == "leftRight" ) {
              .#{$name}-#{$sides}--#{$sizeDefinition}.#{$name}-#{$sides}--#{$sizeDefinition} {
                #{$type}-right: $sizeValue;
                #{$type}-left: $sizeValue;
              }
            } @else if ( $sides == "topBottom" ) {
              .#{$name}-#{$sides}--#{$sizeDefinition}.#{$name}-#{$sides}--#{$sizeDefinition} {
                #{$type}-top: $sizeValue;
                #{$type}-bottom: $sizeValue;
              }
            } @else if ( $sides == "inline" ) {
              .#{$name}-#{$sides}--#{$sizeDefinition}.#{$name}-#{$sides}--#{$sizeDefinition} {
                #{$type}-right: $sizeValue;
                #{$type}-bottom: $sizeValue;
              }
            } @else {
              .#{$name}-#{$sides}--#{$sizeDefinition}.#{$name}-#{$sides}--#{$sizeDefinition} {
                #{$type}-#{$sides}: $sizeValue;
              }
            }
        }
      }
      @else {
        @if( $sizeDefinition == default ){
            .#{$name} {
              #{$type}: $sizeValue;
            }
        } @else {
          .#{$name}--#{$sizeDefinition} {
            #{$type}: $sizeValue;
          }
        }
        @each $side, $sides in $elementSides {
          @if( $sizeDefinition == default ) {
            @if( $sides == "leftRight" ) {
              .#{$name}-#{$sides} {
                #{$type}-right: $sizeValue;
                #{$type}-left: $sizeValue;
              }
            } @else if( $sides == "topBottom" ) {
              .#{$name}-#{$sides} {
                #{$type}-top: $sizeValue;
                #{$type}-bottom: $sizeValue;
              }
            } @else if ( $sides == "inline" ) {
              .#{$name}-#{$sides} {
                #{$type}-right: $sizeValue;
                #{$type}-bottom: $sizeValue;
              }
            }
            @else {
              .#{$name}-#{$sides} {
                #{$type}-#{$sides}: $sizeValue;
              }
            }
          } @else {
            @if( $sides == "leftRight" ) {
              .#{$name}-#{$sides}--#{$sizeDefinition} {
                #{$type}-right: $sizeValue;
                #{$type}-left: $sizeValue;
              }
            } @else if ( $sides == "topBottom" ) {
              .#{$name}-#{$sides}--#{$sizeDefinition} {
                #{$type}-top: $sizeValue;
                #{$type}-bottom: $sizeValue;
              }
            } @else if ( $sides == "inline" ) {
              .#{$name}-#{$sides}--#{$sizeDefinition} {
                #{$type}-right: $sizeValue;
                #{$type}-bottom: $sizeValue;
              }
            } @else {
              .#{$name}-#{$sides}--#{$sizeDefinition} {
                #{$type}-#{$sides}: $sizeValue;
              }
            }
          }
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PADDING & MARGIN ON DIRECT CHILDREN
// This second loop is almost identical to the prior
// ie - margins-right--large would be like adding margin-right--large to all direct children
$elementChildrenSpacingTypes: (
    margins: "margin",
    paddings: "padding",
);

$elementChildrenSides: (
    top: "top",
    right: "right",
    bottom: "bottom",
    left: "left",
    leftRight: "leftRight",
    topBottom: "topBottom",
    inline: "inline",
);

$elementChildrenSizes: (
 // note - this order will effect inheritence
 // ie - the lower the item, it will overwrite those above it
  default: var(--space),
  smallest: var(--space--smallest),
  smaller: var(--space--smaller),
  small: var(--space--small),
  large: var(--space--large),
  larger: var(--space--larger),
  largest: var(--space--largest),
  none: 0
);


////////////////////////////////////////////////////////////////////////////////
// CREATION LOOP

@each $name, $type in $elementChildrenSpacingTypes {
  @each $sizeDefinition, $sizeValue in $elementChildrenSizes {

    @if( map-get($config--cssFeatures, $name) == true ) {
      @if( $sizeDefinition == default ) {
          .#{$name}.#{$name} > *:not([class*='#{$type}-']) {
            #{$type}: $sizeValue;
          }
      } @else {
          .#{$name}--#{$sizeDefinition}.#{$name}--#{$sizeDefinition} > *:not([class*='#{$type}-']) {
            #{$type}: $sizeValue;
          }
      }
      @each $side, $sides in $elementChildrenSides {
        @if( $sizeDefinition == default ) {
          @if( $sides == "leftRight" ) {
            .#{$name}-#{$sides} > *:not([class*='#{$type}-']) {
              #{$type}-right: $sizeValue;
              #{$type}-left: $sizeValue;
            }
          } @else if ( $sides == "topBottom" ) {
            .#{$name}-#{$sides} > *:not([class*='#{$type}-']) {
              #{$type}-top: $sizeValue;
              #{$type}-bottom: $sizeValue;
            }
          } @else if ( $sides == "inline" ) {
            .#{$name}-#{$sides} > *:not([class*='#{$type}-']) {
              #{$type}-right: $sizeValue;
              #{$type}-bottom: $sizeValue;
            }
          } @else {
            .#{$name}-#{$sides} > *:not([class*='#{$type}-']) {
              #{$type}-#{$sides}: $sizeValue;
            }
          }
        } @else {
          @if( $sides == "leftRight" ) {
            .#{$name}-#{$sides}--#{$sizeDefinition} > *:not([class*='#{$type}-']) {
              #{$type}-right: $sizeValue;
              #{$type}-left: $sizeValue;
            }
          } @else if ( $sides == "topBottom" ) {
            .#{$name}-#{$sides}--#{$sizeDefinition} > *:not([class*='#{$type}-']) {
              #{$type}-top: $sizeValue;
              #{$type}-bottom: $sizeValue;
            }
          } @else if ( $sides == "inline" ) {
            .#{$name}-#{$sides}--#{$sizeDefinition} > *:not([class*='#{$type}-']) {
              #{$type}-right: $sizeValue;
              #{$type}-bottom: $sizeValue;
            }
          } @else {
            .#{$name}-#{$sides}--#{$sizeDefinition} > *:not([class*='#{$type}-']) {
              #{$type}-#{$sides}: $sizeValue;
            }
          }
        }
      }
    }


  }
}
