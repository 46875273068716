@import 'dependencies/all'; // _custom-config imported in dependencies/config/_config.scss

/* Magma v1.0 Custom Styles */

@import
  "base/all",
  'layout/all',
  'components/all',
  "utilities/all";

// A place to put possible edits/updates to existing Magma classes
@import 'magma-updates';
