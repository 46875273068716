@use 'sass:math';

.u-fill,
.u-fills > *,
.u-fills--even > * {
    // Fills available space, if two siblings have u-fill they will split available space
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
 }

.u-fill--even > .u-fill,
.u-fills--even > * {
  width: 100%;
}

.u-preserve {
  // Doesnt let an item get 'squished' by siblings
  @include preserve }

.u-shrink {
  flex-shrink: 1;
}

.u-right {
  // u-right is added to an item which gives it the appearance of a right float
  // the item it is applied on fills up the space to the right and aligns its text right
  flex-grow: 1;
  text-align: right; }

.u-right.u-truncate {
  // u-truncate has flex: 0 on it, we want to make sure that
  // items with u-right 'grow' and fall to the right
  flex-grow: 1; }

.u-center {
  justify-content: center; // horizontal center inside items
  align-items: center; } // vertical center inside items

.u-justify-content--center {
  justify-content: center; } // horizontal center inside items

.u-justify-content--right { // row--end is an alternative
  justify-content: flex-end; } // will align children to the right side

.u-align-items--center {
  align-items: center; } // vertical center inside items

.u-align-items--top {
  align-items: flex-start; }

.u-align-items--start {
  align-items: start; }

.u-align-items--bottom {
  align-items: flex-end; }

.u-justify-content--spaceAround {
  justify-content: space-around; } // added to a row or flex item to apply to children

.u-justify-content--spaceBetween {
  justify-content: space-between; } // added to a row or flex item to apply to children

.u-align-self--stretch {
  align-self: stretch; }

.u-align-self--center {
  align-self: center; }

.u-align-self--start {
  align-self: flex-start; }

.u-align-self--end {
  align-self: flex-end; }

.u-flex--bottom {
  margin-top: auto; } // used similar to a float, will push a flex item down

.u-flex--right {
  margin-left: auto; } // used similar to a float, will align a flex item right

.u-flex--left {
  margin-right: auto; } // used similar to a float, will align a flex item left

.u-flex--top {
  margin-bottom: auto; } // used similar to a float, will push a flex item down

.u-flex--center {
  margin-bottom: auto; } // used similar to a float, will push a flex item to center
.u-display--flex {
  display: flex;
}
.u-justify-content--center {
  justify-content: center; }
  @for $n from 1 through 12 {
    .u-flex-basis--#{$n} {
      @if $n == 12 { flex-basis: 100%; }
      @else { flex-basis: percentage( math.div($n, 12)); }
    }
  }
.u-flex-direction--row{
  flex-direction: row;
}
.u-flex-direction--column{
  flex-direction: column;
}
.u-flex-direction--column-reverse {
  flex-direction: column-reverse;
}
