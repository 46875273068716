// button group c-props
:root {
  --btn-group--bg-color: #{$background-color--black--5};
  --btn-group--border-color: #{$border-color--black--light};
  --btn-group--border-radius: var(--input--border-radius);

  --btn-group-btn--bg-color: transparent;
  --btn-group-btn--border-color: transparent;
  --btn-group-btn--font-color: var(--interaction);

  --btn-group-btn--bg-color--hover: #{$background-color--white--1};
  --btn-group-btn--border-color--hover: transparent;
  --btn-group-btn--font-color--hover: var(--interaction--darker);

  --btn-group-btn--bg-color--selected: #{$background-color--white--0};
  --btn-group-btn--border-color--selected: #{$border-color--black--dark};
  --btn-group-btn--font-color--selected: #{$font-color--default};
}

.button-group {
  @include flex();
  width: max-content;
  background-color: var(--btn-group--bg-color);
  padding: var(--space--smallest);
  border: .1rem solid transparent;
  border-color: var(--btn-group--border-color);
  border-radius: var(--btn-group--border-radius);
  // Regular Buttons
  > .button {
    @include transition(fast);
    background-color: var(--btn-group-btn--bg-color);
    border: solid .1rem var(--btn-group-btn--border-color);
    color: var(--btn-group-btn--font-color);
    padding: 0 var(--space--large);
    &:hover:not([disabled]):not(.is-selected),
    &:focus:not([disabled]):not(.is-selected)  {
      background-color: var(--btn-group-btn--bg-color--hover);
      border-color: var(--btn-group-btn--border-color--hover);
      color: var(--btn-group-btn--font-color--hover);
    }
    &:not(:last-of-type) {
      margin-right: var(--space--smallest);
    }
  }
  // Radio Buttons
  > .group {
    @include truncate;
    overflow: visible;
    > input[type="radio"] {
      + label {
        @include transition(fast);
        background-color: var(--btn-group-btn--bg-color);
        border: solid .1rem var(--btn-group-btn--border-color);
        color: var(--btn-group-btn--font-color);
        @include truncate;
        width: initial;
        min-width: var(--minHitArea);
        height: var(--minHitArea);
        line-height: var(--minHitArea);
        border-radius: var(--button--border-radius, var(--input--border-radius));
        padding: 0 var(--space--smaller);
        @include flex();
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &::before {
          display: none !important;
          visibility: hidden !important;
        }
      }
      &:hover:not([disabled]):not(:checked) + label,
      &:focus:not([disabled]):not(:checked) + label,
      &:hover:not([disabled]):not(.is-selected) + label,
      &:focus:not([disabled]):not(.is-selected) + label {
        background-color: var(--btn-group-btn--bg-color--hover);
        border-color: var(--btn-group-btn--border-color--hover);
        color: var(--btn-group-btn--font-color--hover);
      }
    }
    &:not(:last-of-type) {
      margin-right: var(--space--smallest);
    }
  }
  // Selected Button
  > .button.is-selected,
  > .group > input[type="radio"]:checked + label,
  > .group > input[type="radio"].is-selected + label {
    @include box-shadow(1);
    background-color: var(--btn-group-btn--bg-color--selected) !important;
    border-color: var(--btn-group-btn--border-color--selected) !important;
    color: var(--btn-group-btn--font-color--selected) !important;
  }
  // non-solid color variations
  &:not(.is-solid) {
    &.is-primary {
      --btn-group-btn--bg-color--selected: var(--interaction);
      --btn-group-btn--border-color--selected: var(--interaction);
      --btn-group-btn--font-color--selected: var(--interaction--contrast);
      --btn-group-btn--font-color--hover: var(--interaction);
    }
  }
  // solid color variations
  &.is-solid {
    --btn-group--bg-color: #{$background-color--black--3};
    --btn-group--border-color: transparent;
    --btn-group-btn--font-color: #{$font-color--default};
    --btn-group-btn--font-color--hover: #{$font-color--default};
    --btn-group-btn--border-color--selected: transparent;
    &.is-primary {
      --btn-group--bg-color: var(--interaction);
      --btn-group--border-color: transparent;
      --btn-group-btn--bg-color--hover: #{$background-color--white--4};
      --btn-group-btn--font-color: var(--interaction--contrast);
      --btn-group-btn--font-color--hover: var(--interaction--contrast);
    }
  }
  // on dark backgrounds
  &.is-contrast {
    --btn-group--bg-color: #{$background-color--black--4};
    --btn-group--border-color: transparent;
    --btn-group--border-radius: var(--input--border-radius); // default

    --btn-group-btn--bg-color: transparent; // default
    --btn-group-btn--border-color: transparent; // default
    --btn-group-btn--font-color: var(--interaction--contrast);

    --btn-group-btn--bg-color--hover: #{$background-color--white--4};
    --btn-group-btn--border-color--hover: transparent; //default
    --btn-group-btn--font-color--hover: var(--interaction--contrast);

    --btn-group-btn--bg-color--selected: #{$background-color--white--0}; // default
    --btn-group-btn--border-color--selected: transparent;
    --btn-group-btn--font-color--selected: var(--active); // default
    &.is-primary:not(.is-solid) {
      --btn-group-btn--bg-color--hover: var(--interaction--light);
      --btn-group-btn--font-color--hover: var(--interaction--contrast);
      --btn-group-btn--bg-color--selected: var(--interaction);
      --btn-group-btn--border-color--selected: var(--interaction--lightest);
      --btn-group-btn--font-color--selected: var(--interaction--contrast);
    }
    &.is-solid:not(.is-primary) {
      --btn-group--bg-color: #{$background-color--black--2};
      --btn-group--border-color: transparent;
    }
  }
  // small version
  &.is-small {
    > .button,
    > .group > input[type="radio"] + label:not(.is-icon) {
      --minHitArea: var(--minHitArea--small);
      padding-right: var(--space);
      padding-left: var(--space);
      font-size: var(--font-size--small);
    }
  }
  // stacked version
  &.is-stacked {
    flex-direction: column;
    > .button {
      &:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: var(--space--smallest);
      }
    }
  }
  // stacked version
  &.row--wrap {
    flex-wrap: wrap;
    flex-flow: row wrap;
  }
}
