$ul--indent--default: var(--space);
$ul--line-height: var(--line-height--base);

ul, ol {
  list-style-position: outside;
  margin: 0 0 var(--space) 0;
  padding: 0 0 0 $ul--indent--default;
  ul, ol {
    margin-bottom: 0;
    padding-left: $ul--indent--default;
    &.is-unstyled {
      padding-left: $ul--indent--default;
    }
  }
  li {
    line-height: $ul--line-height;
  }
  &.is-unstyled {
    list-style-type: none;
    padding-left: 0;
  }
}

ul {
  list-style-type: disc; // none | disc | circle | square
  &.is-disc { list-style-type: disc; }
  &.is-circle { list-style-type: circle; }
  &.is-square { list-style-type: square; }
}

ol {
  list-style-type: decimal; // none | decimal | decimal-leading-zero | lower-roman | upper-roman | lower-alpha | upper-alpha
  &.is-decimal { list-style-type: decimal; }
  &.is-decimal-leading-zero { list-style-type: decimal-leading-zero; }
  &.is-lower-roman { list-style-type: lower-roman; }
  &.is-upper-roman { list-style-type: upper-roman; }
  &.is-lower-alpha { list-style-type: lower-alpha; }
  &.is-upper-alpha { list-style-type: upper-alpha; }
}
