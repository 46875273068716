.queue {
  --organism--background: var(--queue--background, var(--organism--background--default));
  --organism--color: var(--queue--color, var(--organism--color--default));
  --organism--min-width: var(--queue--width);
  --organism--width: var(--queue--width);
}

.queue > .header .titles:not(.t1):not(.t2):not(.t3):not(.t4):not(.t5):not(.t6)  {
  @include titles(4);
}

// Add transition to queues that are toggle-able
.queue.is-open,
.queue.is-closed {
  @include transition(fast);
}

.queue.is-closed {
  --organism--min-width: 0;
  --organism--width: 0;

  & > *:not(.pullTab) {
    // hide interior bits except for the toggle when queue is closed
    // without this, things like search will overflow
    display: none;
    opacity: 0; }

}
