.u-color--default {
  color: var(--font-color--base); }

.u-color--white {
  color: #FFF;
  --font-color--base: #FFF;
  --font-color--header: #FFF;
  --font-color--display: #FFF;
}

.u-color--black {
  color: #000; }

.u-color--interaction {
  color: var(--interaction); }

.u-color--interaction--darkest {
  color: var(--interaction--darkest); }

.u-color--brand--1 {
  color: var(--brand--1);
  &--contrast {
    color: var(--brand--1--contrast);
  }
}

.u-color--brand--2 {
  color: var(--brand--2);
  &--contrast {
    color: var(--brand--2--contrast);
  }
}

.u-color--brand--3 {
  color: var(--brand--3);
  &--contrast {
    color: var(--brand--3--contrast);
  }
}

.u-color--brand--4 {
  color: var(--brand--4);
  &--contrast {
    color: var(--brand--4--contrast);
  }
}

.u-color--brand--5 {
  color: var(--brand--5);
  &--contrast {
    color: var(--brand--5--contrast);
  }
}

.u-color--brand--6 {
  color: var(--brand--6);
  &--contrast {
    color: var(--brand--6--contrast);
  }
}
