.body {
  @include flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: var(--body--background-color);
}

.page {
  @include flex(column);
  flex-grow: 1;
  overflow: auto;
  overflow-x: hidden; // ag-grid tweak
}

.page-content {
  @include flex;
  flex-grow: 1;
  overflow: hidden;
}

.page-content-main {
  @include flex(column);
  flex-grow: 1;
  overflow: auto;
}


//------------------------------------------------------------------------------

.page-container {
  // main page content.. this element is for setting the width, position (center, left, full width) of the main content

  &,
  &--left,
  &--center,
  &--right {
    display: flex;
    flex-flow: column;
    max-width: var(--page-container--max-width);
    flex-shrink: 0;
    width: 100%;
    padding: var(--page-container--padding--tb) var(--page-container--padding--lr);

    &.is-narrow {
      --page-container--max-width: 800px; }

    &.is-fullWidth {
      --page-container--max-width: 100vw; }

    }

  &--left {
    align-self: flex-start;
    margin: 0;
    margin-right: auto; }

  &--center {
    margin: 0 auto;
    align-self: center; }

  &--right {
    align-self: flex-end;
    margin: 0;
    margin-left: auto; }

}

.u-max-width--page-container {
  max-width: var(--page-container--max-width);
}
